import React from "react";
import { Box, Snackbar, Typography, Slide, SxProps } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { sxStyles } from "./ToastSnackbar.styles";
import { TransitionProps } from "@mui/material/transitions";
import IMAGES from "lib/assets/images";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectNotifications } from "lib/redux/notifications/selectors";
import { closeBottomNotification } from "lib/redux/notifications";

const HIDE_DURATION = 3000; // No. of (ms) to wait before onClose is called
const TRANSITION_DURATION = 250; // No. of (ms) a transition lasts, e.g. entering / exiting
export type Icon = "calendar" | "success";

const ToastSnackbar = () => {
  const classes = sxStyles();
  const { open, message, icon } = useAppSelector(selectNotifications).bottom;
  const dispatch = useAppDispatch();
  return (
    <Snackbar
      data-testid="snackbar"
      sx={classes.barRoot}
      open={open}
      onClose={() => {
        dispatch(closeBottomNotification());
      }}
      autoHideDuration={HIDE_DURATION}
      transitionDuration={TRANSITION_DURATION}
      TransitionComponent={Transition}
      message={
        <Box sx={classes.contentMessage} display="flex">
          {getIcon(icon, classes.icon)}
          <Typography variant="h5" sx={{ fontSize: "1.5rem" }}>
            {message}
          </Typography>
        </Box>
      }
    />
  );
};

const getIcon = (icon: Icon | null, iconClassName: SxProps) => {
  if (icon === "calendar") {
    return (
      <CalendarTodayIcon sx={iconClassName} data-testid="calendar-today-icon" />
    );
  } else if (icon === "success") {
    return (
      <Box
        data-testid="check-circle-icon"
        component={"img"}
        src={IMAGES.general.CheckIcon}
        sx={iconClassName}
      />
    );
  } else {
    return null;
  }
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ToastSnackbar;
