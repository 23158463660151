import { useMemo, useState } from "react";
import { IterableElement } from "lib/data/data.types";
import AppointmentFormHeader from "../../AppointmentFormHeader/AppointmentFormHeader";
import CreateApptFormField from "../CreateApptFormField/CreateApptFormField";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CreateApptFormFieldStruct } from "../CreateApptFormField/CreateApptFormField.types";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { CreateApptFormProps } from "./CreateApptForm.types";
import { PersonalParticularsFormStruct } from "../../Form/Form.types";
import { validatePhoneNumber } from "lib/util/ValidatorUtil/phoneNumberValidator/phoneNumberValidator";
import { validateEmail } from "lib/util/ValidatorUtil/emailValidator/emailValidator";
import { useSelector } from "react-redux";
import { RootState } from "lib/redux/root/redux.types";
import { sxStyles } from "./CreateApptForm.styles";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import PersonalParticularsForm from "../../PersonalParticularsForm/PersonalParticularsForm";
import { useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentsSystemSettings,
  selectCreateAppointment,
} from "ui/appointment/ducks/selectors";
import { SpecialtyFromSystemSettings } from "ui/appointment/components/createAppointment/pages/SpecialtySelectionPage/SpecialtySelectionPage.type";

// ==========================
// COMPONENT
// ==========================

export const CreateApptForm = ({
  personalParticularsFormField,
  handlePersonalParticularsFormFieldChange,
  submitIsLoading,
  openConfirmationModal,

  onSubmitAppointmentRequest,
}: CreateApptFormProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();

  // Redux States
  const { selectedReferral, selectedSpecialty } = useAppSelector(
    selectCreateAppointment,
  );
  const institutionName =
    useAppSelector(selectCreateAppointment).selectedInstitution ?? "";
  const institutionCode =
    useAppSelector(selectCreateAppointment).selectedInstitutionCode ?? "";
  const specialtyValue: string | null =
    useAppSelector(selectAppointmentsSystemSettings).values["ServiceList"]
      ?.Value ?? null;
  const parsedSpecialtyValue = specialtyValue ? JSON.parse(specialtyValue) : [];
  const specialtyForInstitution = parsedSpecialtyValue?.[institutionCode] ?? [];

  const specialtyList: IterableElement[] | [] =
    specialtyForInstitution.length > 0
      ? specialtyForInstitution
          .map((specialty: SpecialtyFromSystemSettings) => {
            return {
              id: specialty.Description ?? "",
              text: specialty.Description ?? "",
            };
          })
          .sort((a: IterableElement, b: IterableElement) =>
            a.text.localeCompare(b.text),
          )
      : [];
  // Local States
  const [createApptFormField, setCreateApptFormField] =
    useState<CreateApptFormFieldStruct>({
      specialty: selectedSpecialty?.description ?? "",
      description: "",
    });
  const [errorDOB, setErrorDOB] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const isSubmitDisabled = useMemo(
    () =>
      disableSubmit({
        specialtyList,
        submitIsLoading,
        createApptFormField,
        personalParticularsFormField,
        errorDOB,
        isChecked,
        selectedReferral,
      }),
    // eslint-disable-next-line
    [
      createApptFormField,
      errorDOB,
      isChecked,
      personalParticularsFormField,
      selectedReferral,
      specialtyForInstitution,
      institutionCode,
      specialtyValue,
      submitIsLoading,
    ],
  );

  // Event fired whenever a field on this reschedule form has been updated
  // name: name attribute of input component
  const handleChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    const updatedCreateApptFormField = {
      ...createApptFormField,
      [name]: value,
    };
    setCreateApptFormField(updatedCreateApptFormField);
  };

  const handleSubmit = () => {
    onSubmitAppointmentRequest({
      IsMakeApptAction: true,
      IsRescheduleApptAction: false,
      IsCancelApptAction: false,
      NewAppointmentReferredby: selectedReferral,
      NewAppointmentSpecialty: createApptFormField.specialty,
      NewAppointmentSymptoms: createApptFormField.description,
      NewAppointmentInstitution: institutionName,
      DepartmentName: createApptFormField.specialty,
    });
  };

  const formValues = useSelector(
    (state: RootState) =>
      state.appointments.systemSettings.values["NUPApptOfflineFormText"]?.Value,
  );

  const {
    Alert: alert,
    MainInfo: mainInfo,
    Notes: notes,
  } = formValues
    ? JSON.parse(formValues)
    : {
        Alert: "",
        MainInfo: "",
        Notes: "",
      };

  return (
    <Box sx={{ p: 2 }}>
      {submitIsLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AppointmentFormHeader alert={alert} mainInfo={mainInfo} />
          <CreateApptFormField
            name={institutionName}
            createApptFormField={createApptFormField}
            handleChange={handleChange}
            specialtyList={specialtyList}
          />
          <PersonalParticularsForm
            personalParticularsFormField={personalParticularsFormField}
            handlePersonalParticularsFormFieldChange={(event: {
              target: { name: string; value: string };
            }) => {
              handlePersonalParticularsFormFieldChange(event);
              setErrorDOB(false);
            }}
            showRequiredForEmail={true}
            onInputError={() => setErrorDOB(true)}
          />

          <Box sx={classes.checkboxContainer}>
            <SimpleCheckbox
              checked={isChecked}
              label={
                <Typography component={"span"} sx={classes.checkBoxLabel}>
                  {notes}
                </Typography>
              }
              value="agreementCheck"
              handleChange={() => setIsChecked((current) => !current)}
              required={true}
            />
          </Box>
          <ButtonsFooter
            isDisabled={isSubmitDisabled}
            nextButtonText="Send request"
            cancelButtonText="Cancel"
            onClickNext={() => {
              if (selectedReferral) {
                handleSubmit();
              }
            }}
            onClickCancel={() => {
              navigate(-1);
            }}
          />
          <ConfirmationModal
            title="Request Submitted"
            body="We will get back to you with a confirmation for this request shortly."
            hideNextButton={true}
            hideCancelButton={false}
            cancelButtonText="Go to Appointment"
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path);
            }}
            open={openConfirmationModal}
            onClose={() => {}}
          />
        </>
      )}
    </Box>
  );
};

// ==========================
// HELPER FUNCTIONS
// ==========================
/**
 * Disable submit button depending on whether required fields are present and of the required format.
 *
 * @param submitIsLoading: whether the submit API call is still loading
 * @param createApptFormField: input fields on the form, specialty, referredBy and symptoms remarks, are mandatory
 * @param personalParticularsFormField : mandatory input fields on the form
 * @param errorDOB: boolean when DOB has error
 * @param isChecked: boolean for agreement checkbox
 * @returns False if all mandatory input fields have been filled in, true otherwise.
 */
const disableSubmit = ({
  specialtyList,
  submitIsLoading,
  createApptFormField,
  personalParticularsFormField,
  errorDOB,
  isChecked,
  selectedReferral,
}: {
  specialtyList: IterableElement[];
  submitIsLoading: boolean;
  createApptFormField: CreateApptFormFieldStruct;
  personalParticularsFormField: PersonalParticularsFormStruct;
  errorDOB: boolean;
  isChecked: boolean;
  selectedReferral: string | null;
}): boolean => {
  if (submitIsLoading) {
    return true;
  }

  if (
    specialtyList.length >= 1 &&
    !createApptFormField.specialty &&
    createApptFormField.specialty.length === 0
  ) {
    // specialty list was provided, but the user has not selected one yet

    return true;
  }

  if (!selectedReferral) {
    return true;
  }

  if (!personalParticularsFormField.name) {
    return true;
  }

  if (!validatePhoneNumber(personalParticularsFormField.contact)) {
    return true;
  }

  if (!personalParticularsFormField.dateOfBirth) {
    return true;
  }

  if (!validateEmail(personalParticularsFormField.email)) {
    return true;
  }

  if (errorDOB) {
    return true;
  }

  if (!isChecked) {
    return true;
  }
  return false;
};

export default CreateApptForm;
