import { PATHS } from "lib/routing/paths/paths";
import { Route } from "./GlobalRoutes.types";

// ============================
// Appointments Module Pages
// ============================
import ServicesPage from "ui/appointment/components/createAppointment/pages/services/ServicesPage/ServicesPage";
import CreateApptAvailableSlotsPage from "ui/appointment/components/createAppointment/pages/availableSlots/CreateApptAvailableSlotsPage/CreateApptAvailableSlotsPage";
import CreateConfirmationPage from "ui/appointment/components/createAppointment/pages/confirmation/ConfirmationPage/ConfirmationPage";
import CreateBundledLinkedApptConfirmationPage from "ui/appointment/components/createAppointment/pages/confirmation/ConfirmationBundledLinkedApptPage/ConfirmationBundledLinkedApptPage";
import RescheApptAvailableSlotsPage from "ui/appointment/components/rescheduleAppointment/pages/availableSlots/RescheApptAvailableSlotsPage/RescheApptAvailableSlotsPage";
import RescheduleConfirmationPage from "ui/appointment/components/rescheduleAppointment/pages/confirmation/ConfirmationPage/ConfirmationPage";
import Form from "ui/appointment/components/forms/Form/Form";
// import QueueChitAndJourneyContainer from "ui/appointment/components/registerAppointment/queueChitAndJourney/QueueChitAndJourney/QueueChitAndJourneyContainer";
import QueueChitAndJourney from "ui/appointment/components/registerAppointment/queueChitAndJourney/QueueChitAndJourney/QueueChitAndJourney";
import SelectLinkedAppts from "ui/appointment/components/rescheduleAppointment/linkedApptsPages/SelectLinkedAppts/SelectLinkedAppts";
// Reschedule Bundled Linked Appointments
import LinkedApptsEntryPointPage from "ui/appointment/components/rescheduleAppointment/pages/EntryPointPage/LinkedApptsEntryPointPage";
import ConfirmedBundledLinkedAppts from "ui/appointment/components/rescheduleAppointment/linkedApptsPages/ConfirmedBundledLinkedAppts/ConfirmedBundledLinkedAppts";
import RescheBundledLinkedApptAvailableSlotsPage from "ui/appointment/components/rescheduleAppointment/linkedApptsPages/RescheBundledLinkedApptAvailableSlotsPage/RescheBundledLinkedApptAvailableSlotsPage";

// ==========================
// MedRefill Module Pages
// ==========================
import SelectRequest from "ui/medrefill/components/selectRequest/SelectRequest";
// ==============================
// My Health Map Module Pages
// ==============================
import Dashboard from "ui/mhm/components/Dashboard/Dashboard";

// =========================
// Payment Module Pages
// ========================
import PaymentDashboard from "ui/payment/components/dashboard/Dashboard";
import BillDetail from "ui/payment/components/dashboard/BillDetail/BillDetail";
import AddedBillDetail from "ui/payment/components/dashboard/AddedBillDetail/AddedBillDetail";
import PaidBills from "ui/payment/components/paidBills/PaidBills";
import TransactionHistory from "ui/payment/components/transactionHistory/TransactionHistory";
// import BillDetail from "ui/payment/components/dashboard/BillDetail/BillDetail";
import PayorInfo from "ui/payment/components/payorInfo/PayorInfo";
import Receipt from "ui/payment/components/receipt/Receipt";

// ==================
// Profile Pages
// ==================
import MyInfoProfile from "../../../../ui/app/components/myProfile/MyInfoProfile/MyInfoProfile";
// ==================
// Health Together Module Pages
// ==================

import UISandbox from "ui/uiSandbox/UISandbox";
import CancelAppointmentPage from "ui/appointment/components/CancelAppointmentPage/CancelAppointmentPage";

// ==================
// Patient Master Pages
// ==================
import PMEntryPage from "ui/app/components/myProfile/PatientMaster/pages/PMEntryPage/PMEntryPage";
import PMComparePage from "ui/app/components/myProfile/PatientMaster/pages/PMComparePage/PMComparePage";
import MyProfile from "../../../../ui/app/components/myProfile/MyProfile/MyProfile";
import CreateLinkedApptAvailableSlotsPage from "ui/appointment/components/createAppointment/pages/availableSlots/CreateBundledLinkedApptAvailableSlotsPage/CreateBundledLinkedApptAvailableSlotsPage";

import HTLandingPage from "ui/healthTogether/components/pages/LandingPage/HTLandingPage";
import CommunityCarePage from "ui/healthTogether/components/pages/CommunityCarePage/CommunityCarePage";
import OtherEnquiriesPage from "ui/payment/components/otherEnquiries/OtherEnquiriesPage";
import HMT from "ui/mhm/components/EnrolledUser/HMT/HMT";
import PaymentDD from "ui/payment/components/common/PaymentDD/PaymentDD";
import BillSummary from "ui/payment/components/billSummary/BillSummary";
import AppointmentPage from "ui/appointment/components/appointmentList/AppointmentPage/AppointmentPage";
import Admin from "ui/admin/Admin";
import InstitutionsPage from "ui/appointment/components/createAppointment/pages/institutions/InstitutionsPage/InstitutionsPage";
import PreferredMailingAddPage from "ui/app/components/myProfile/PreferredMailingAddPage/PreferredMailingAddPage";
import SelfHelpToolsPage from "ui/healthTogether/components/pages/SelfHelpToolsPage/SelfHelpToolsPage";
import LogOut from "lib/components/auth/LogOut/LogOut";
import RescheLinkedApptAvailableSlotsPage from "ui/appointment/components/rescheduleAppointment/linkedApptsPages/RescheLinkedApptAvailableSlotsPage/RescheLinkedApptAvailableSlotsPage";
import SpecialtySelectionPage from "ui/appointment/components/createAppointment/pages/SpecialtySelectionPage/SpecialtySelectionPage";
import ReferralSelectionPage from "ui/appointment/components/createAppointment/pages/ReferralSelectionPage/ReferralSelectionPage";
import PractitionerSelectionPage from "ui/appointment/components/createAppointment/pages/PractitionerSelectionPage/PractitionerSelectionPage";
import KioskQuestionnairePage from "ui/appointment/components/registerAppointment/kiosk/KioskQuestionnairePage/KioskQuestionnairePage";
/**
 * A router that defines the paths and properties specific for routing in the entire application.
 *
 * == USAGE ==
 *
 * Define routes in the below setup by:
 *   - Specifying the path in the browser that, when navigated to, will render its
 *     corresponding component.
 *   - Function that returns the React component to be rendered, preferably a component
 *     that represents the page (top-level entry point) of the functionality.
 *
 * == FOR FUTURE DEVELOPMENT ==
 *
 * Note:
 *   The flat and long hierarchy of this routing setup may not scale when we need
 *   to manage many routes for every module. Hence, do feel free to modify the shape
 *   of this schema (e.g. add sub nodes / combine nested objects) to suit that scale.
 */

export const ROUTES: Route[] = [
  // =========================
  // UI Sandbox
  // =========================
  // This route is used for quick dev mock for UI
  {
    path: PATHS.UI_SANDBOX.path,
    exact: true,
    component: () => <UISandbox />,
  },

  // =========================
  // ADMIN
  // =========================
  {
    path: PATHS.ADMIN.path,
    exact: true,
    component: () => <Admin />,
  },

  // ======================
  // MY PROFILE
  // ======================
  {
    path: PATHS.MY_PROFILE_MOBILE.path,
    exact: true,
    component: () => <MyProfile />,
  },
  {
    path: PATHS.MY_PROFILE_MOBILE_ERROR.path,
    exact: true,
    component: () => <MyProfile error={true} />,
  },
  {
    path: PATHS.MY_PROFILE.path,
    exact: true,
    component: () => <MyProfile />,
  },
  {
    path: PATHS.PM_ENTRY_MOBILE.path,
    exact: true,
    component: () => <PMEntryPage />,
  },
  {
    path: PATHS.PM_ENTRY.path,
    exact: true,
    component: () => <PMEntryPage />,
  },
  {
    path: PATHS.MY_INFOPROFILE.path, //other places
    exact: true,
    component: () => <MyInfoProfile />,
  },
  {
    path: PATHS.MYINFO_AND_PROFILE_COMPARE.path,
    exact: true,
    component: () => <PMComparePage />,
  },
  {
    path: PATHS.MYINFO_AND_PROFILE_CONFIRM.path, //edit my info
    exact: true,
    component: () => <MyInfoProfile />,
  },

  {
    path: PATHS.MY_PROFILE_PREFERRED_MAILING.path,
    exact: true,
    component: () => <PreferredMailingAddPage />,
  },

  // ======================
  // APPOINTMENT MODULE
  // ======================
  {
    path: PATHS.APPOINTMENT_MOBILE.path,
    exact: true,
    component: () => <AppointmentPage />,
  },

  // ------------------------
  // Make an Appointment Flow
  // ------------------------
  {
    path: PATHS.APPOINTMENT_CREATE_INSTITUTIONS.path,
    exact: true,
    component: () => <InstitutionsPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_INSTITUTIONS_MOBILE.path,
    exact: true,
    component: () => <InstitutionsPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_SERVICES.path,
    exact: true,
    component: () => <ServicesPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_SPECIALTY.path,
    exact: true,
    component: () => <SpecialtySelectionPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_REFERRAL.path,
    exact: true,
    component: () => <ReferralSelectionPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_CONSULTANT.path,
    exact: true,
    component: () => <PractitionerSelectionPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path,
    exact: true,
    component: () => <CreateApptAvailableSlotsPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_LINKED_AVAILABLE_SLOTS.path,
    exact: true,
    component: () => <CreateLinkedApptAvailableSlotsPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_CONFIRM.path,
    exact: true,
    component: () => <CreateConfirmationPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_BUNDLED_LINKED_CONFIRM.path,
    exact: true,
    component: () => <CreateBundledLinkedApptConfirmationPage />,
  },
  {
    path: PATHS.APPOINTMENT_CREATE_FORM.path,
    exact: true,
    component: () => <Form apptFormType="make" />,
  },
  {
    path: PATHS.APPOINTMENT_OPEN_FORM.path,
    exact: true,
    component: () => <Form apptFormType="open" />,
  },
  {
    path: PATHS.APPOINTMENT_MAMMOGRAM_FORM.path,
    exact: true,
    component: () => <Form apptFormType="make-mammogram" />,
  },

  // ----------------------------
  // Re-schedule Appointment Flow
  // ----------------------------

  {
    path: PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS.path,
    exact: true,
    component: () => <RescheApptAvailableSlotsPage />,
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS_MOBILE.path,
    exact: true,
    component: () => <RescheApptAvailableSlotsPage />,
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_CONFIRM.path,
    exact: true,
    component: () => <RescheduleConfirmationPage />,
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_FORM.path,
    exact: true,
    component: () => <Form apptFormType="reschedule" />,
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_FORM_MOBILE.path,
    exact: true,
    component: () => <Form apptFormType="reschedule" />,
  },
  {
    path: PATHS.APPOINTMENT_MISSED_FORM.path,
    exact: true,
    component: () => <Form apptFormType="missed" />,
  },

  // ------------------------------
  // Re-schedule Linked Appointments Entry Flow
  // ------------------------------
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_LINKED_ENTRY.path,
    exact: true,
    component: () => <LinkedApptsEntryPointPage fromMobile={false} />,
  },

  {
    path: PATHS.APPOINTMENT_RESCHEDULE_LINKED_ENTRY_MOBILE.path,
    exact: true,
    component: () => <LinkedApptsEntryPointPage fromMobile={true} />,
  },

  // ----------------------------
  // Re-schedule Linked Appointments Flow
  // ----------------------------

  {
    path: PATHS.APPOINTMENT_RESCHEDULE_LINKED_AVAILABLE_SLOTS.path,
    exact: true,
    component: () => <RescheLinkedApptAvailableSlotsPage />,
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM.path,
    exact: true,
    component: () => <SelectLinkedAppts fromMobile={false} />,
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE.path,
    exact: true,
    component: () => <SelectLinkedAppts fromMobile={true} />,
  },

  // ===================
  // Bundled Linked Appointment Reschedule
  // ==================
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS_MOBILE.path,
    exact: true,
    component: () => (
      <RescheBundledLinkedApptAvailableSlotsPage fromMobile={true} />
    ),
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS.path,
    exact: true,
    component: () => (
      <RescheBundledLinkedApptAvailableSlotsPage fromMobile={false} />
    ),
  },
  {
    path: PATHS.APPOINTMENT_RESCHEDULE_BUNDLED_LINKED_CONFIRM.path,
    exact: true,
    component: () => <ConfirmedBundledLinkedAppts fromMobile={false} />,
  },

  // ----------------------------
  // Cancel Appointment Flow
  // ----------------------------
  {
    path: PATHS.APPOINTMENT_CANCEL_FORM.path,
    exact: true,
    component: () => <Form apptFormType="cancel" />,
  },
  {
    path: PATHS.APPOINTMENT_CANCEL_FORM_MOBILE.path,
    exact: true,
    component: () => <Form apptFormType="cancel" />,
  },
  {
    path: PATHS.APPOINTMENT_CANCEL_MOBILE.path,
    exact: true,
    component: () => <CancelAppointmentPage />,
  },
  {
    path: PATHS.APPOINTMENT_CANCEL.path,
    exact: true,
    component: () => <CancelAppointmentPage />,
  },

  // ----------------------------
  // Register Appointment Flow
  // ----------------------------
  {
    path: PATHS.APPOINTMENT_REGISTER_KIOSK.path,
    exact: true,
    component: () => <KioskQuestionnairePage isCheckIn={false} />,
  },
  {
    path: PATHS.APPOINTMENT_REGISTER_KIOSK_MOBILE.path,
    exact: true,
    component: () => <KioskQuestionnairePage isCheckIn={false} />,
  },
  {
    path: PATHS.APPOINTMENT_CHECKIN.path,
    exact: true,
    component: () => <KioskQuestionnairePage isCheckIn={true} />,
  },
  {
    path: PATHS.APPOINTMENT_CHECKIN_MOBILE.path,
    exact: true,
    component: () => <KioskQuestionnairePage isCheckIn={true} />,
  },
  {
    path: PATHS.APPOINTMENT_QUEUECHIT_JOURNEY.path,
    exact: true,
    component: () => <QueueChitAndJourney />,
  },
  {
    path: PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_MOBILE.path,
    exact: true,
    component: () => <QueueChitAndJourney />,
  },
  {
    path: PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path,
    exact: true,
    component: () => <QueueChitAndJourney />,
  },

  // ==================================
  // MEDICATION REFILL / TOPUP MODULE
  // ==================================
  {
    path: PATHS.MEDICATIONS_MOBILE.path,
    exact: true,
    component: () => <SelectRequest />,
  },

  // ==================================
  // PAYMENT MODULE
  // ==================================
  {
    path: PATHS.PAYMENT_MOBILE.path,
    exact: true,
    component: () => <PaymentDashboard />,
  },
  {
    path: PATHS.PAYMENT_PAID_BILLS.path,
    exact: true,
    component: () => <PaidBills />,
  },
  {
    path: PATHS.PAYMENT_TRANSACTION_HISTORY.path,
    exact: true,
    component: () => <TransactionHistory />,
  },
  {
    path: PATHS.PAYMENT_OUTSTANDING_BILL_DETAILS.path,
    exact: true,
    component: () => <BillDetail />,
  },
  {
    path: PATHS.PAYMENT_ADDED_BILL_DETAILS.path,
    exact: true,
    component: () => <AddedBillDetail />,
  },
  {
    path: PATHS.PAYMENT_BILL_SUMMARY_MOBILE.path,
    exact: true,
    component: () => <BillSummary />,
  },

  {
    path: PATHS.PAYMENT_BILL_SUMMARY.path,
    exact: true,
    component: () => <BillSummary />,
  },
  {
    path: PATHS.PAYMENT_PAYOR_INFO.path,
    exact: true,
    component: () => <PayorInfo />,
  },
  {
    path: PATHS.PAYMENT_RECEIPT.path,
    exact: true,
    component: () => <Receipt />,
  },

  {
    path: PATHS.PAYMENT_RECEIPT_DD.path,
    exact: true,
    component: () => <PaymentDD />,
  },
  {
    path: PATHS.PAYMENT_PAYOR_INFO_TRY_AGAIN.path,
    exact: true,
    component: () => <PayorInfo />,
  },
  {
    path: PATHS.PAYMENT_OTHER_ENQUIRIES.path,
    exact: true,
    component: () => <OtherEnquiriesPage />,
  },

  // =========================
  //  HEALTH TOGETHER MODULE
  // =========================
  {
    path: PATHS.HT_MOBILE.path,
    exact: true,
    component: () => <HTLandingPage />,
  },
  {
    path: PATHS.HT_SELF_HELP_TOOLS.path,
    exact: true,
    component: () => <SelfHelpToolsPage />,
  },

  {
    path: PATHS.HT_COMMUNITY_CARE.path,
    exact: true,
    component: () => <CommunityCarePage />,
  },

  // ==================================
  // My Health Map MODULE
  // ==================================
  {
    path: PATHS.MHM_DASHBOARD.path,
    exact: true,
    component: () => <Dashboard />,
  },
  {
    path: PATHS.MHM_ENROLLED_USER_HMT.path,
    exact: true,
    component: () => <HMT />,
  },

  // ==================================
  // Log Out MODULE
  // ==================================
  {
    path: PATHS.LOGOUT.path,
    exact: true,
    component: () => <LogOut />,
  },
];
