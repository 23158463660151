import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import PageLayout from "lib/components/layout/Page/Page";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { RescheBundledLinkedApptAvailableSlotsPageProps as RescheBundledLinkedApptAvailableSlotsPagePageProps } from "./RescheBundledLinkedApptAvailableSlotsPage.types";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import RescheduleNotice from "../../pages/availableSlots/RescheduleNotice/RescheduleNotice";
import AvailableSlotsHeader from "ui/appointment/components/common/availableSlots/AvailableSlotsHeader/AvailableSlotsHeader";
import AvailableBundledSlotsSectionContainer from "ui/appointment/components/common/availableSlots/AvailableBundledSlotsSection/AvailableBundledSlotsSectionContainer";
import { ACTIONS, PATHS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import PreCancelRescheduleModal from "ui/appointment/components/common/PreCancelRescheduleModal/PreCancelRescheduleModal";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setIsPreConfirmationModalOpen } from "ui/appointment/ducks/appointmentsSlice";
import {
  selectAppointments,
  selectRescheduleLinkedAppointments,
} from "ui/appointment/ducks/selectors";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { getHealthPlanCompletionDate } from "api/appointment/GetHealthPlanCompletionDate/getHealthPlanCompletionDate";
import { GetHealthPlanCompletionDate } from "api/appointment/GetHealthPlanCompletionDate/getHealthPlanCompletionDate.fromApi.types";
import { selectUser } from "lib/redux/user/selectors";
import { selectNavigation } from "lib/redux/navigation/selectors";
import { setCurrentFlowAction, setCustomisedBack } from "lib/redux/navigation";
import { updateRescheduleLinkedAppointmentVisitedSlotsPage } from "ui/appointment/ducks";
import { setSlotDetailsForRescheduleBundledLinkedAppointment } from "ui/appointment/ducks/appointmentsSlice";

const RescheBundledLinkedApptAvailableSlotsPage = ({
  fromMobile,
}: RescheBundledLinkedApptAvailableSlotsPagePageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux State
  const { isPreConfirmationModalOpen } = useAppSelector(
    selectRescheduleLinkedAppointments,
  );
  const memberIdentifier = useAppSelector(selectUser).memberIdentifier;
  const appointment =
    useAppSelector(selectAppointments).rescheduleLinkedAppointment
      .selectedLinkedAppointment;
  const preventDirectBackNavigationState =
    useAppSelector(selectNavigation).customisedBack;

  const [healthPlanCompletionDate, setHealthPlanCompletionDate] =
    useState<GetHealthPlanCompletionDate | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(() => {
    dispatch(setCurrentFlowAction("APPOINTMENT_RESCHEDULE"));
    dispatch(updateRescheduleLinkedAppointmentVisitedSlotsPage(true));

    const fetchHealthPlanCompletionDate = async () => {
      setLoading(true);
      try {
        const response = await getHealthPlanCompletionDate(
          memberIdentifier,
          appointment?.rescheduleToDays,
          appointment?.appointmentStartDateTime,
        );
        setHealthPlanCompletionDate(response);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (appointment?.isHsgAppt) {
      fetchHealthPlanCompletionDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const centerContactNo = null; //TODO: get backend to send contact number

  const resolvedSearchDateFrom = healthPlanCompletionDate?.SubsequentStartDate
    ? getDateFromISOString(healthPlanCompletionDate?.SubsequentStartDate)
    : getDateFromISOString(appointment?.slotSearchDateRangeFrom ?? "");

  const resolvedSearchDateTo = healthPlanCompletionDate?.SubsequentEndDate
    ? getDateFromISOString(healthPlanCompletionDate?.SubsequentEndDate)
    : getDateFromISOString(appointment?.slotSearchDateRangeTo ?? "");

  const isSameDay = isSameDayReschedule(
    appointment?.departmentCode || null,
    appointment?.logoCode || null,
  );

  useEffect(() => {
    //log event to google analytics
    if (isSameDay) {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_SAMEDAY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_ADVANCE);
    }
  }, [isSameDay]);

  //customized back navigation when back button is clicked
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      // reset the back button flag setting
      dispatch(setCustomisedBack(false));
      fromMobile
        ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
        : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  return (
    <PageLayout>
      <>
        {/* appointment.clinician */}
        {appointment && (
          <AvailableSlotsHeader
            appointmentType={
              appointment.type === "actualised" ? null : appointment.type
            }
            location={appointment.institutionName}
            service={appointment.serviceDisplayName}
            currentDateTime={formatDate(formatDateToISO(appointment.datetime))}
            inAdvanceNoticeComponent={<RescheduleNotice />}
          />
        )}

        {/* // Wait until we've checked if there are any values for the min & max
          slot search dates // before rendering the datepicker, so that the min
          & max dates are set properly */}
        {loading !== false ? (
          <Box
            display="flex"
            align-items="center"
            justifyContent="center"
            mt={4}
          >
            <CircularProgress />
          </Box>
        ) : (
          <AvailableBundledSlotsSectionContainer
            isLinkedAppointment={true}
            isUrti={false}
            isHsgSubsequent={healthPlanCompletionDate?.IsHsgSubsequent}
            isNewAppointment={false}
            isSameDayBooking={isSameDay}
            minStartDate={resolvedSearchDateFrom}
            maxEndDate={resolvedSearchDateTo}
            shouldGetAvailableSlots={resolvedSearchDateTo !== null}
            onSelect={(selectedBundledSlots) => {
              const selectedBundledSlotsData = selectedBundledSlots.map(
                (slot) => {
                  return {
                    datetime: slot.date,
                    slotId: slot.id,
                    resource: slot.resource,
                  };
                },
              );

              dispatch(
                setSlotDetailsForRescheduleBundledLinkedAppointment([
                  ...selectedBundledSlotsData,
                ]),
              );
              if (!appointment?.isHsgAppt) {
                logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_DETAILS);
              }
              navigate(
                PATHS.APPOINTMENT_RESCHEDULE_BUNDLED_LINKED_CONFIRM.path,
              );
            }}
            onCancel={() => {
              fromMobile
                ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
                : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            centerContactNo={centerContactNo}
          />
        )}
      </>

      <PreCancelRescheduleModal
        isOpen={isPreConfirmationModalOpen}
        onClickFirstButton={() => {
          logEventToGoogleAnalytics(EVENTS.CLICK_APPT_RESCH_HEALTHMATTERS_BACK);
          fromMobile
            ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
            : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
        }}
        onClickSecondButton={() => {
          logEventToGoogleAnalytics(
            EVENTS.CLICK_APPT_RESCH_HEALTHMATTERS_PROCEED,
          );
          dispatch(setIsPreConfirmationModalOpen(false));
        }}
        type={"reschedule"}
      />
    </PageLayout>
  );
};

export default RescheBundledLinkedApptAvailableSlotsPage;
