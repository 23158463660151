import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import { MessageActions } from "./messageActions";
import {
  setIsAcknowledged,
  setMobileMessage,
} from "lib/redux/navigation/navigationSlice";
import { store } from "lib/redux/root";
import { MessageActionType } from "./messageActions.types";

declare global {
  interface Window {
    webkit: any;
  }
}

/**
 * ## InitializeMessageChannel
 * The `initializeMessageChannel` object has two functions `iOS` and `android` that will add message event listeners. Each function will return an object with a method to send messages to the respective platform.
 * @returns {sendIOSMessage} - A function that sends a message to iOS.
 * @returns {sendAndroidMessage} - A function that sends a message to Android.
 */
export const initializeMessageChannel = {
  // ===================
  // iOS message channel
  // ====================

  iOS: () => {
    window.addEventListener("iosEvent", iosHandler);

    function iosHandler(event: Event) {
      const parsedMsg: MessageActionType = parseJson(
        (event as any).detail.data,
      );

      if (parsedMsg.action === "establish") {
        // Echo acknowledge back to iOS
        window.webkit?.messageHandlers?.iosMessageHandler.postMessage(
          JSON.stringify(MessageActions.acknowledge()),
        );

        store.dispatch(setIsAcknowledged(true));
      }
      store.dispatch(setMobileMessage((event as any).detail.data as string));
    }

    window.onbeforeunload = () => {
      window.removeEventListener("iosEvent", iosHandler);
    };

    return {
      sendIOSMessage: (messageAction: MessageActionType | string) => {
        validateAndSendMessage(messageAction, (stringifiedMessageAction) => {
          window.webkit?.messageHandlers?.iosMessageHandler?.postMessage(
            stringifiedMessageAction,
          );
        });
      },
    };
  },
  // =======================
  // Android message channel
  // =======================
  android: () => {
    let messagePort: MessagePort;

    window.onmessage = onInitMessageHandler;

    function onInitMessageHandler(windowMessageEvent: MessageEvent<any>) {
      if (
        typeof windowMessageEvent.data === "string" &&
        windowMessageEvent.ports.length > 0
      ) {
        const parsedMsg: MessageActionType = parseJson(
          windowMessageEvent.data ?? "",
        );
        if (parsedMsg.action === "establish") {
          // Transfer port ref
          messagePort = windowMessageEvent.ports[0];
          // Echo acknowledge back to Android
          messagePort.postMessage(JSON.stringify(MessageActions.acknowledge()));

          store.dispatch(setIsAcknowledged(true));
        }
        store.dispatch(setMobileMessage(windowMessageEvent.data as string));

        messagePort.onmessage = onPortMessageHandler;
      }
    }

    function onPortMessageHandler(event: MessageEvent<any>) {
      store.dispatch(setMobileMessage(event.data as string));
    }

    window.onbeforeunload = () => {
      window.removeEventListener("message", onInitMessageHandler);
    };

    return {
      sendAndroidMessage: (messageAction: MessageActionType | string) => {
        if (!messagePort) {
          console.error("Android message port not found");
          return;
        } else {
          validateAndSendMessage(messageAction, (stringifiedMessageAction) => {
            messagePort.postMessage(stringifiedMessageAction);
          });
        }
      },
    };
  },
};

/**
 * The function `validateAndSendMessage` validates a message action and sends it using a callback
 * function after converting it to a string if necessary.
 * @param {MessageActionType | string} messageAction - The `messageAction` parameter in the
 * `validateAndSendMessage` function can be of type `MessageActionType` or a string. It is the action
 * that needs to be sent as a message.
 * @param sendMessageCallback - The `sendMessageCallback` parameter is a function that takes a single
 * argument of type `string` and does not return anything. It is used to send a message action in
 * string format to a specified destination or perform some action based on the message content.
 * @returns The function `validateAndSendMessage` returns either an error message "No message action
 * provided" if no `messageAction` is provided, or it sends the `messageAction` by calling the
 * `sendMessageCallback` function after processing it.
 */
export const validateAndSendMessage = (
  messageAction: MessageActionType | string,
  sendMessageCallback: (stringifiedMessageAction: string) => void,
) => {
  if (!messageAction) {
    return console.error("No message action provided");
  }

  if (typeof messageAction === "object") {
    messageAction = JSON.stringify(messageAction);
  }

  if (typeof messageAction === "string") {
    if (window.location.href.includes("localhost")) {
      console.log("messageAction sent: ", messageAction);
    }
    sendMessageCallback(messageAction);
  }
};
