import { MedRefillState, MedRefillSystemSetting } from "./medrefill.types";

// Initial state representing the time when appointments are not initialized yet
export const initialState: MedRefillState = {
  requestType: null,
  reorder: false,
  showMyChartMedication: false,

  // =====================
  // PAGE-SPECIFIC
  // =====================

  institution: {
    facilityName: null,
    facilityId: null,
    cluster: null,
    clinicId: 0,
  },

  // Particulars
  particulars: {
    isPatient: true,
    requesterId: null,
    requesterNric: null,
    requesterName: null,
    patientName: null,
    patientNric: null,
    contactNumber: null,
    email: null,
    postalCode: null,
    unitNo: null,
    blockNo: null,
    address: null,
  },

  // =====================
  // GENERAL
  // Parts / Slices of state that are used throughout the MedRefill flow, and are usually
  // hydrated by an API call that fetches some metadata about the user's input fields.
  // =====================

  systemSettings: {
    lookup: new Map<string, MedRefillSystemSetting[]>(),
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
  },
};
