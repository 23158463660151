/**
 * Configuration Keys (defined in `processs.env`)
 *
 * For this web application, the config keys are defined in .env-cmdrc.js.
 */

export const REACT_APP_ENV = "REACT_APP_ENV";
export const REACT_APP_URL = "REACT_APP_URL";
export const REACT_APP_API_BASE_URL = "REACT_APP_API_BASE_URL";
export const REACT_APP_PRESCRIBED_MEDICATION_URL =
  "REACT_APP_PRESCRIBED_MEDICATION_URL";
export const REACT_APP_CROSS_CLUSTER_URL = "REACT_APP_CROSS_CLUSTER_URL";
export const REACT_APP_AUTOPAY_ZOOM_URL = "REACT_APP_AUTOPAY_ZOOM_URL";
export const REACT_APP_SCREEN_FOR_LIFE_URL = "REACT_APP_SCREEN_FOR_LIFE_URL";
export const REACT_APP_SCREEN_FOR_LIFE_BACK_URL =
  "REACT_APP_SCREEN_FOR_LIFE_BACK_URL";
export const REACT_APP_MEDREFILL_URL = "REACT_APP_MEDREFILL_URL";
export const REACT_APP_MAMMOGRAM_SURVEY_URL = "REACT_APP_MAMMOGRAM_SURVEY_URL";
export const REACT_APP_API_API_KEY = "REACT_APP_API_API_KEY";
export const REACT_APP_ENABLE_AUTHENTICATION =
  "REACT_APP_ENABLE_AUTHENTICATION";
export const REACT_APP_FIREBASE_API_KEY = "REACT_APP_FIREBASE_API_KEY";
export const REACT_APP_FIREBASE_AUTH_DOMAIN = "REACT_APP_FIREBASE_AUTH_DOMAIN";
export const REACT_APP_FIREBASE_DATABASEURL = "REACT_APP_FIREBASE_DATABASEURL";
export const REACT_APP_FIREBASE_PROJECT_ID = "REACT_APP_FIREBASE_PROJECT_ID";
export const REACT_APP_FIREBASE_STORAGE_BUCKET =
  "REACT_APP_FIREBASE_STORAGE_BUCKET";
export const REACT_APP_FIREBASE_MESSAGING_SENDER_ID =
  "REACT_APP_FIREBASE_MESSAGING_SENDER_ID";
export const REACT_APP_FIREBASE_APP_ID = "REACT_APP_FIREBASE_APP_ID";
export const REACT_APP_FIREBASE_MEASUREMENT_ID =
  "REACT_APP_FIREBASE_MEASUREMENT_ID";

export const REACT_APP_AUTH_CLIENT_ID = "REACT_APP_AUTH_CLIENT_ID";
export const REACT_APP_AUTH_REDIRECT_URI = "REACT_APP_AUTH_REDIRECT_URI";
export const REACT_APP_AUTH_SCOPE = "REACT_APP_AUTH_SCOPE";
export const REACT_APP_AUTH_AUTHORITY = "REACT_APP_AUTH_AUTHORITY";
export const REACT_APP_AUTH_CLIENT_SECRET = "REACT_APP_AUTH_CLIENT_SECRET";
