import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationsState } from "./notifications.types";
import { Icon } from "lib/components/notice/ToastSnackbar/ToastSnackbar";

export const initialState: NotificationsState = {
  bottom: {
    open: false,
    message: "",
    icon: null,
  },
  error: {
    open: false,
    message: null,
    customizedButtonText: null,
  },
};

/**
 * The slice reducer for the part of state that represents notifications.
 * Contains the management of notifications, e.g. when / where a notice should be shown.
 */
const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    emitBottomNotification(
      state,
      action: PayloadAction<{ message: string; icon: Icon }>,
    ) {
      state.bottom.open = true;
      state.bottom.message = action.payload.message;
      state.bottom.icon = action.payload.icon;
    },
    closeBottomNotification(state) {
      state.bottom.open = initialState.bottom.open;
    },

    handleErrorModal(
      state,
      action: PayloadAction<{
        open: boolean;
        message: string | null;
        buttonText?: string | null;
      }>,
    ) {
      state.error.open = action.payload.open;
      state.error.message = action.payload.message;
      state.error.customizedButtonText = action.payload.buttonText || null;
    },
    closeErrorModal(state) {
      state.error.open = false;
    },
  },
});

export const {
  emitBottomNotification,
  closeBottomNotification,
  handleErrorModal,
  closeErrorModal,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
