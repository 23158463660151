import { BundledLinkedApptCardProps } from "./BundledLinkedApptCard.types";
import { Box, Typography, Card } from "@mui/material";
import { sxStyles } from "./BundledLinkedApptCard.styles";
import ConfirmationCard from "../../pages/confirmation/ConfirmationCard/ConfirmationCard";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import IMAGES from "lib/assets/images";

const BundledLinkedApptCard = ({
  appointment,
  slot,
  newDateTime,
  onSelect,
}: BundledLinkedApptCardProps) => {
  const classes = sxStyles();
  return (
    <Box
      data-testid={"cardRegion"}
      sx={classes.cardRegion}
      onClick={() => onSelect()}
    >
      <Box sx={classes.linkedCardHeader}>
        <Typography sx={classes.institutionText}>
          {appointment.institutionName}
        </Typography>

        <Typography sx={classes.serviceDisplayName}>
          {slot
            ? slot.resource
              ? slot.resource
              : appointment.serviceDisplayName
            : appointment.serviceDisplayName}
        </Typography>

        <Typography sx={classes.departmentName}>
          {appointment.departmentName}
        </Typography>

        <Typography sx={classes.location}>{appointment.location}</Typography>

        <Typography sx={classes.visitType}>{appointment.visitType}</Typography>
      </Box>

      <Box sx={{ ...classes.layout, ...classes.cardSectionLayout }}>
        <Box sx={classes.cardLayout}>
          <Card sx={classes.card}>
            <Box sx={classes.cardtitle}>
              <Typography sx={classes.titleLeftText}>Current</Typography>
              <ConfirmationCard slotDateTime={appointment.datetime} />
            </Box>

            <Box
              component={"img"}
              src={IMAGES.general.ForwardIcon}
              sx={classes.arrow}
            />

            <Box sx={classes.cardtitle}>
              <Typography sx={classes.titleRightText}>New</Typography>
              {newDateTime ? (
                <Card sx={classes.setNewCard}>
                  <ConfirmationCard
                    slotDateTime={getDateFromISOString(newDateTime)}
                  />
                </Card>
              ) : (
                <Card sx={classes.setNewCard}>
                  <Box
                    component={"img"}
                    src={IMAGES.appointment.TapToSetNewDate}
                    alt="Tap to set a new Date"
                    sx={classes.setNewCardImg}
                  />
                </Card>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default BundledLinkedApptCard;
