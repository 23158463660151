import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import {
  MobileIntegrationMedrefillState,
  MedRefillState,
} from "./medrefill.types";
import { Setting } from "api/shared/GetSystemSettings/getSystemSettings.fromApi.types";

/**
 * The slice reducer for the part of state that represents the user's medication refill information.
 */
const medrefillSlice = createSlice({
  name: "medrefill",
  initialState,
  reducers: {
    initializeMedrefillFromMobileIntegration(
      state,
      action: PayloadAction<MobileIntegrationMedrefillState>,
    ) {},
    clearState(state) {
      state.reorder = false;
      state.institution = initialState.institution;
      state.particulars = initialState.particulars;
    },
    updateState(state, action: PayloadAction<MedRefillState>) {
      state.requestType = action.payload.requestType;
      state.institution = action.payload.institution;
      state.particulars = action.payload.particulars;
    },
    updateReorderFlag(state, action: PayloadAction<boolean>) {
      state.reorder = action.payload;
    },
    setSystemSettingsIsLoading(state, action: PayloadAction<boolean>) {
      state.systemSettings.isLoading = action.payload;
    },
    setSystemSettingsHasErrored(state, action: PayloadAction<boolean | null>) {
      state.systemSettings.hasErrored = action.payload;
    },
    setSystemSettingsErrorMessage(state, action: PayloadAction<string | null>) {
      state.systemSettings.errorMessage = action.payload;
    },
    setSystemSettingsLookup(state, action: PayloadAction<Setting[]>) {
      state.systemSettings.lookup.clear();
      for (let system of action.payload) {
        let mapValue = state.systemSettings.lookup.get(system.SubCategory);
        let itemToAdd = {
          value: Number(system.Value),
          displayValue: system.DisplayValue,
        };
        if (mapValue !== undefined) {
          state.systemSettings.lookup.set(system.SubCategory, [
            ...mapValue,
            itemToAdd,
          ]);
        } else {
          state.systemSettings.lookup.set(system.SubCategory, [itemToAdd]);
        }
      }
    },
    setShowMyChartMedication(state, action: PayloadAction<boolean>) {
      state.showMyChartMedication = action.payload;
    },
  },
});

export const {
  updateReorderFlag,
  initializeMedrefillFromMobileIntegration,
  clearState,
  updateState,
  setSystemSettingsIsLoading,
  setSystemSettingsHasErrored,
  setSystemSettingsErrorMessage,
  setSystemSettingsLookup,
  setShowMyChartMedication,
} = medrefillSlice.actions;

export default medrefillSlice.reducer;
