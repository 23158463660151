import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { ACTIONS, PATHS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import PageLayout from "lib/components/layout/Page/Page";
import AvailableSlotsHeader from "../../../../common/availableSlots/AvailableSlotsHeader/AvailableSlotsHeader";
import AvailableSlotsSectionContainer from "../../../../common/availableSlots/AvailableSlotsSection/AvailableSlotsSectionContainer";
import RescheduleNotice from "../RescheduleNotice/RescheduleNotice";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import PreCancelRescheduleModal from "ui/appointment/components/common/PreCancelRescheduleModal/PreCancelRescheduleModal";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentMeta,
  selectAppointments,
  selectRescheduleLinkedAppointments,
} from "ui/appointment/ducks/selectors";
import { setIsPreConfirmationModalOpen } from "ui/appointment/ducks/appointmentsSlice";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { updateSlotDetailsForReschedule } from "ui/appointment/ducks";
import { fetchDocumentForAllTypes } from "ui/appointment/ducks/thunks";

const resolveIsSameDayBooking = (
  deptCode: string | null,
  logoCode: string | null,
  sameDayBookingFlag: boolean,
) => {
  if (deptCode) {
    return isSameDayReschedule(deptCode, logoCode);
  } else {
    return sameDayBookingFlag;
  }
};

const resolveSlotSearchDateTime = (slotDateString: string | null) => {
  return getDateFromISOString(slotDateString);
};

const hasDefinedSlotSearchDates = (
  slotSearchDateRangeFrom: Date | null,
  slotSearchDateRangeTo: Date | null,
) => {
  if (slotSearchDateRangeFrom !== null && slotSearchDateRangeTo !== null) {
    return true;
  } else {
    return false;
  }
};

const RescheApptAvailableSlotsPage = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const dispatch = useAppDispatch();

  // Redux State
  const { isPreConfirmationModalOpen } = useAppSelector(
    selectRescheduleLinkedAppointments,
  );
  const { centerContactNo } = useAppSelector(selectAppointmentMeta);
  const currentDateTime = formatDate(
    useAppSelector(selectAppointments).reschedule.selectedDateTime,
  );
  const location =
    useAppSelector(selectAppointments).reschedule.selectedInstitution || "";
  const service =
    useAppSelector(selectAppointments).reschedule.selectedService || "";
  const isLoadingSlotAndPreConsultMsg =
    useAppSelector(selectAppointments).documentByType.isLoading || "";

  const {
    appointmentType,
    departmentCode,
    logoCode,
    isSameDayBooking: sameDayBookingFlag,
    slotSearchDateRangeFrom: resolvedSlotSearchDateRangeFrom,
    slotSearchDateRangeTo: resolvedSlotSearchDateRangeTo,
  } = useAppSelector(selectAppointmentMeta);
  const slotSearchDateRangeFrom = resolveSlotSearchDateTime(
    resolvedSlotSearchDateRangeFrom,
  );
  const slotSearchDateRangeTo = resolveSlotSearchDateTime(
    resolvedSlotSearchDateRangeTo,
  );
  const hasDefinedSlotSearchDatesFromAppointment = hasDefinedSlotSearchDates(
    slotSearchDateRangeFrom,
    slotSearchDateRangeTo,
  );

  const isSameDayBooking = resolveIsSameDayBooking(
    departmentCode,
    logoCode,
    sameDayBookingFlag,
  );

  useEffect(() => {
    dispatch(setCurrentFlowAction("APPOINTMENT_RESCHEDULE"));
  }, [dispatch]);

  //fetch pre-consultation to be displayed in confirmation page
  // check isLoadingPreConsultMsg to ensure only fetch once in the rescheduling flow
  // fetch in this page to avoid displaying loading spinner on loading of both slots page and confirmation page
  useEffect(() => {
    if (isLoadingSlotAndPreConsultMsg === null) {
      dispatch(fetchDocumentForAllTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSlotAndPreConsultMsg]);

  // Select the appropriate dates to use when selecting available slots / making relevant API calls
  let resolvedSearchDateFrom: Date | null = null;
  let resolvedSearchDateTo: Date | null = null;

  if (hasDefinedSlotSearchDatesFromAppointment) {
    resolvedSearchDateFrom = slotSearchDateRangeFrom;
    resolvedSearchDateTo = slotSearchDateRangeTo;
  }

  useEffect(() => {
    //log event to google analytics
    if (isSameDayBooking) {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_SAMEDAY);
    } else {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_ADVANCE);
    }
  }, [isSameDayBooking]);

  const handleOnBack = () => {
    if (
      routerLocation.pathname ===
      PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS_MOBILE.path
    ) {
      mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
    } else {
      navigate(-1);
    }
  };

  const handleOnBackPreResch = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_APPT_RESCH_HEALTHMATTERS_BACK);
    if (
      routerLocation.pathname ===
      PATHS.APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS_MOBILE.path
    ) {
      mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
    } else {
      navigate(-1);
    }
  };

  return (
    <PageLayout>
      {isLoadingSlotAndPreConsultMsg ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AvailableSlotsHeader
            appointmentType={appointmentType}
            location={location}
            service={service}
            currentDateTime={currentDateTime}
            inAdvanceNoticeComponent={<RescheduleNotice />}
          />
          {/* // Wait until we've checked if there are any values for the min & max
          slot search dates // before rendering the datepicker, so that the min
          & max dates are set properly */}
          <AvailableSlotsSectionContainer
            centerContactNo={centerContactNo}
            isNewAppointment={false}
            isSameDayBooking={isSameDayBooking}
            isUrti={false}
            minStartDate={resolvedSearchDateFrom}
            maxEndDate={resolvedSearchDateTo}
            shouldGetAvailableSlots={resolvedSearchDateTo !== null}
            onSelect={(slotId, slotDateTime) => {
              dispatch(
                updateSlotDetailsForReschedule({
                  slotId,
                  slotDateTime,
                }),
              );
              logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_DETAILS);
              navigate(PATHS.APPOINTMENT_RESCHEDULE_CONFIRM.path);
            }}
            onCancel={handleOnBack}
          />

          <PreCancelRescheduleModal
            isOpen={isPreConfirmationModalOpen}
            onClickFirstButton={handleOnBackPreResch}
            onClickSecondButton={() => {
              logEventToGoogleAnalytics(
                EVENTS.CLICK_APPT_RESCH_HEALTHMATTERS_PROCEED,
              );
              dispatch(setIsPreConfirmationModalOpen(false));
            }}
            type={"reschedule"}
          />
        </>
      )}
    </PageLayout>
  );
};

export default RescheApptAvailableSlotsPage;
