import { combineReducers, AnyAction } from "redux";
import { persistReducer } from "redux-persist";
import { enableMapSet } from "immer";
import { localStoragePersistConfig } from "./redux-persist/config";
import { ApptUserPreferenceState } from "../apptUserPreference/apptUserPreference.types";

import { userSlice } from "../user";
import { notificationsSlice } from "../notifications";
import { appointmentsSlice } from "ui/appointment/ducks";
import { medrefillSlice } from "ui/medrefill/ducks";
import { systemSlice } from "../system";
import { apptUserPreferenceSlice } from "../apptUserPreference";

import { navigationSlice } from "../navigation";
import { paymentSlice } from "ui/payment/ducks";
import { UserState } from "../user/user.types";
import { userSessionStoragePersistConfig } from "../user/config";
import { mhmSlice } from "ui/mhm/ducks";
import { systemSessionStoragePersistConfig } from "../system/config";
import { SystemState } from "../system/system.types";
import { PaymentState } from "ui/payment/ducks/payment.types";
import { paymentSessionStoragePersistConfig } from "ui/payment/ducks/config";
import { NavigationState } from "../navigation/navigation.types";
import { navigationSessionStoragePersistConfig } from "../navigation/config";
import { pmSlice } from "ui/app/components/myProfile/PatientMaster/ducks";
import { AppointmentsState } from "ui/appointment/ducks/appointments.types";
import { appointmentsSessionStoragePersistConfig } from "ui/appointment/ducks/config";

enableMapSet();

const rootReducer = combineReducers({
  // App-wide
  system: persistReducer<SystemState, AnyAction>(
    systemSessionStoragePersistConfig,
    systemSlice,
  ),
  user: persistReducer<UserState, AnyAction>(
    userSessionStoragePersistConfig,
    userSlice,
  ),
  notifications: notificationsSlice,
  navigation: persistReducer<NavigationState, AnyAction>(
    navigationSessionStoragePersistConfig,
    navigationSlice,
  ),

  // Module-specific
  appointments: persistReducer<AppointmentsState, AnyAction>(
    appointmentsSessionStoragePersistConfig,
    appointmentsSlice,
  ),

  medRefill: medrefillSlice,
  payments: persistReducer<PaymentState, AnyAction>(
    paymentSessionStoragePersistConfig,
    paymentSlice,
  ),
  mhm: mhmSlice,

  pm: pmSlice,

  // Module-specific Persisted User Preferences
  apptUserPreference: persistReducer<ApptUserPreferenceState, AnyAction>(
    localStoragePersistConfig,
    apptUserPreferenceSlice,
  ),
});

export default rootReducer;
