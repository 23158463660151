/* istanbul ignore file */
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { createRoot } from "react-dom/client";

// Routes
import { PATHS } from "lib/routing";

// Application Wrappers that inject additional parameters into each react component
import CustomThemeProvider from "lib/theme/CustomThemeProvider";
import ToastSnackbar from "lib/components/notice/ToastSnackbar/ToastSnackbar";
import { persistor, store } from "./lib/redux/root";

// Mobile Integration Handling
import { MobileIntegrationParsed } from "lib/redux/system/system.types";
import { parsePayload } from "lib/mobileIntegration/parser/parsePayloadFromMobile";
import { validateAndDispatchToAllReducers } from "lib/mobileIntegration/globalDispatcher/globalDispatcher";
import * as RoutesHijackModule from "lib/components/navigation/customizedRoutes/routesToHijack";
import * as RoutesRedirectionModule from "lib/components/navigation/customizedRoutes/routesToRedirect";
import { setCustomisedBack } from "lib/redux/navigation";
import { SYSTEM_DEVICE_PLATFORM_ANDROID } from "lib/mobileIntegration/constants";
import renderErrorMsg from "lib/util/ConsoleUtil/renderErrorMsg";
import { Box, Typography } from "@mui/material";
import { AUTH_CONFIG } from "lib/configs/authentication";
import { OidcProvider } from "@axa-fr/react-oidc";
import { PersistGate } from "redux-persist/integration/react";
import LoadingPage from "lib/components/auth/LoadingPage/LoadingPage";
import AppRouteRendererContainer from "lib/components/navigation/AppRouteRenderer/AppRouteRendererContainer";

/**
 * Performs any initialization processes in the application before rendering.
 *
 * @param {string} payload  Stringified payload passed From mobile client
 * @returns {boolean}  True if the render succeeded, false otherwise
 */

const mobileInit = (payload: string): boolean => {
  try {
    const parsedPayload = parsePayload<MobileIntegrationParsed>(payload);
    console.debug(
      `[React][mobileInit] Parsed payload: ${JSON.stringify(parsedPayload)}`,
    );
    validateAndDispatchToAllReducers(store, parsedPayload);
    return true;
  } catch (error) {
    console.error(
      `[React][mobileInit] An error occurred while preparing the application for render. Error message: '${renderErrorMsg(
        error,
      )}'.`,
    );
    return false;
  }
};

/**
 * Performs back navigation for Android hardware back button
 *
 */
const androidBackNav = (): boolean => {
  try {
    const devicePlatform = store.getState().system.devicePlatform;
    if (devicePlatform === SYSTEM_DEVICE_PLATFORM_ANDROID) {
      const path = window.location.pathname.replace(PATHS.ROOT.path, "");
      if (RoutesHijackModule.containsBackNavHijackedPath(path)) {
        store.dispatch(setCustomisedBack(true));
      } else if (RoutesRedirectionModule.containsPath(path)) {
        RoutesRedirectionModule.executeRedirect(path);
      } else {
        window.history.back();
      }
      return true;
    } else {
      throw new Error(`Device Platform ${devicePlatform} is not supported`);
    }
  } catch (error) {
    console.error(
      `[React][mobileBackNav] An error occurred while triggering back navigation. Error message: '${renderErrorMsg(
        error,
      )}'.`,
    );
    return false;
  }
};

const UnsecuredPage = () => (
  <Box>
    <Typography style={{ color: "red" }}>
      If you see this page, the link you have clicked on is under Clickjacking
      security attack.
    </Typography>
    <Typography>
      Please inform team with the reference of the application from where you
      clicked this link.
    </Typography>
  </Box>
);

/**
 * Renders the entire UI DOM hierarchy
 */

const renderReactOnDOM = (): boolean => {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <OidcProvider {...AUTH_CONFIG}>
        <Provider store={store}>
          <CustomThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BrowserRouter basename={PATHS.ROOT.path}>
                {/* Check to prevent clickjacking */}
                {window.self === window.top ? (
                  <PersistGate loading={<LoadingPage />} persistor={persistor}>
                    <AppRouteRendererContainer />
                  </PersistGate>
                ) : (
                  <UnsecuredPage />
                )}
              </BrowserRouter>
              {/* App-wide Bottom notifications */}
              <ToastSnackbar />
            </LocalizationProvider>
          </CustomThemeProvider>
        </Provider>
      </OidcProvider>
    </React.StrictMode>,
  );
  return true;
};

export { mobileInit, renderReactOnDOM, androidBackNav };
