import { useState } from "react";
import { FooterIconsProps } from "./FooterIcons.types";
import { sxStyles } from "./FooterIcons.styles";
import IMAGES from "lib/assets/images";
import { Box, Typography } from "@mui/material";
import UserConsentModal from "lib/components/modals/ConfirmationModal/UserConsentModal/UserConsentModal";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowserForStandardPage,
} from "lib/routing/navigate/navigate";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { ACTIONS } from "lib/routing";
import CompactCard from "lib/components/cards/CompactCard/CompactCard";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import ExternalLinkCard from "lib/components/cards/ExternalLinkCard/ExternalLinkCard";
import { medrefillUrl } from "lib/configs/configs/config";

const CARDS = [
  {
    title: "Prescription List",
    body: "View your medication list, prescribed dosage and instructions",
  },
  {
    title: "Order Medication",
    body: "Refill medication or repeat prescription",
  },
];

const MED_RECORD_LINK_TITLE = "Medication Record ";
const OTHER_PROVIDERS_TITLE = "From other providers";
/* This is a functional component that is used to display the icons at the bottom of the page. */
const FooterIcons = ({
  prescribedMedicationConsentFlag,
  shouldDisplayMedicationRecord,

  prescriptionRedirect,
}: FooterIconsProps) => {
  const classes = sxStyles();

  const [openConsentModal, setOpenConsentModal] = useState(false);

  const eventHandlers = {
    medrefill: () => {
      navigateToExternalInMobileBrowserForStandardPage(medrefillUrl(), "ss");
    },

    myChart: () => {
      logEventToGoogleAnalytics(EVENTS.SELECT_MY_CHART);
      mobileNavigate(ACTIONS.MY_CHART.MEDICATION);
    },

    userConsent: () => {
      setOpenConsentModal(false);
    },
  };

  return (
    <Box sx={classes.footerIconsContainer}>
      {/* Conditionally display My Chart medication */}
      {shouldDisplayMedicationRecord && (
        <CompactCard
          title={CARDS[0].title}
          body={CARDS[0].body}
          titleIcon={IMAGES.medrefill.PrescriptionListIcon}
          onClickCard={eventHandlers.myChart}
        />
      )}

      {/* MedRefill icon */}
      <CompactCard
        title={CARDS[1].title}
        body={MapRawStringToReactElement(CARDS[1].body)}
        titleIcon={IMAGES.medrefill.MedRefillIcon}
        onClickCard={eventHandlers.medrefill}
      />

      <Typography sx={classes.otherProvidersTitle}>
        {OTHER_PROVIDERS_TITLE}
      </Typography>
      <ExternalLinkCard
        externalLinkObject={[
          {
            title: MED_RECORD_LINK_TITLE,
            endIcon: "externalLink",
            onClickCard: () => {
              // if consent is given, redirect to prescribed medication page
              if (prescribedMedicationConsentFlag) {
                prescriptionRedirect();
              }
              // open modal to indicate consent is not given
              else {
                setOpenConsentModal(true);
              }
            },
          },
        ]}
      />

      <UserConsentModal
        open={openConsentModal}
        onClickNext={eventHandlers.userConsent}
      />
    </Box>
  );
};

export default FooterIcons;
