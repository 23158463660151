import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, generateDefaultRegisteredAppt } from "./initialState";
import {
  MobileIntegrationAppointmentState,
  DocumentByTypeMap,
  SettingsList,
  linkedAppointment,
  CheckInSuccessState,
  CreateLinkedAppointmentNewSlot,
  HSG,
} from "./appointments.types";
import {
  Appointment,
  AppointmentsLookUpAndListing,
} from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import { Question as TriageQuestion } from "api/appointment/GetTriageQuestionnaire/getTriageQuestionnaire.fromApi.types";
import { Journey } from "api/appointment/GetQueueStatusAndJourney/getQueueStatusAndJourney.toUi.types";
import { RescheduleLinkedAppointmentNewSlot } from "./appointments.types";
import { Question as MammogramQuestion } from "api/appointment/GetMammogramQuestionnaire/getMammogramQuestionnaire.fromApi.types";
import { isSameDayCreate } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";

/**
 * The slice reducer for the part of state that represents the user's appointments.
 * Contains the management of appointment information, e.g. create, re-schedule, cancel.
 */
const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    // === For Initializing Appointment Metadata from mobile integration ===

    initializeAppointmentsFromMobileIntegration(
      state,
      action: PayloadAction<MobileIntegrationAppointmentState>,
    ) {
      const {
        appointmentId,
        regAppointmentId,
        mobileRegQNo,
        exceedRescheduleLimit,
        appointmentStartDateTime,
        vcSupportStatus,
        isHsgAppt,
        centerContactNo,
        institutionName,
        location,
        institutionCode,
        clinicCode,
        departmentCode,
        departmentName,
        targetSystem,
        visitTypeId,
        serviceDisplayName,
        slotSearchDateRangeFrom,
        slotSearchDateRangeTo,
        actualizeStatus,
        calendarTitle,
        calendarLocation,
        regInstitutionCode,
        consultType,
        expectedOrderDate,
        linkedAppointments,
        currentClinicCode,
        hasPromptedRegTutorial,
        appointmentTimeToArrive,
        encounterId,
        slotId,
        rescheduleToDays,
      } = action.payload;

      // Appointment General
      state.hasPromptedRegTutorial = hasPromptedRegTutorial;

      // Appointment Metadata
      state.appointmentMeta.appointmentId = appointmentId;
      state.appointmentMeta.institutionCode = institutionCode;
      state.appointmentMeta.targetSystem = targetSystem; //taken from SourceSystem
      state.appointmentMeta.departmentCode = departmentCode;
      state.appointmentMeta.departmentName = departmentName;
      state.appointmentMeta.visitTypeId = visitTypeId;
      state.appointmentMeta.slotSearchDateRangeFrom = slotSearchDateRangeFrom;
      state.appointmentMeta.slotSearchDateRangeTo = slotSearchDateRangeTo;
      state.appointmentMeta.location = location;
      state.appointmentMeta.clinicCode = clinicCode;
      state.appointmentMeta.actualizeStatus = actualizeStatus;
      state.appointmentMeta.calendarTitle = calendarTitle;
      state.appointmentMeta.calendarLocation = calendarLocation;
      state.appointmentMeta.regInstitutionCode = regInstitutionCode;
      state.appointmentMeta.consultType = consultType;
      // state.appointmentMeta.providerId = providerId; //taken from ResourceCode
      state.appointmentMeta.vcSupportStatus = vcSupportStatus;
      state.appointmentMeta.isHsgAppt = isHsgAppt;
      state.appointmentMeta.HSG.isHsgAppt = isHsgAppt;
      state.appointmentMeta.centerContactNo = centerContactNo;
      state.appointmentMeta.expectedOrderDate = expectedOrderDate;
      state.appointmentMeta.linkedAppointments = linkedAppointments ?? null;
      state.appointmentMeta.datetimeString = appointmentStartDateTime;
      state.appointmentMeta.isVirtualConsultAppointment =
        vcSupportStatus === "supported";
      state.appointmentMeta.regAppointmentId = regAppointmentId;
      state.appointmentMeta.exceedRescheduleLimit = exceedRescheduleLimit;
      state.appointmentMeta.institutionName = institutionName;
      state.appointmentMeta.slotId = slotId;
      state.appointmentMeta.rescheduleToDays = rescheduleToDays;

      // Appointment Reschedule
      state.reschedule.selectedInstitution = institutionName;
      state.reschedule.selectedService = serviceDisplayName;
      state.reschedule.selectedDateTime = appointmentStartDateTime;
      state.reschedule.selectedDepartmentName = departmentName;

      // Appointment Registration
      if (appointmentId && action.payload.mobileRegQNo) {
        if (state.register[appointmentId] === undefined) {
          state.register[appointmentId] = generateDefaultRegisteredAppt();
        }
        state.register[appointmentId].queueNumber = mobileRegQNo;
        state.register[appointmentId].institutionName = institutionName;
        state.register[appointmentId].consultType = consultType;
        state.register[appointmentId].currentClinicCode = currentClinicCode;
        state.register[appointmentId].appointmentTimeToArrive =
          appointmentTimeToArrive;
        state.register[appointmentId].encounterId = encounterId;
      }

      // Persist
      if (appointmentId) {
        if (state.persist[appointmentId] === undefined) {
          state.persist[appointmentId] = {
            hideContactField: false,
          };
        }
      }
    },

    // === For Initializing all Appointments ===

    initializeAppointments(
      state,
      action: PayloadAction<AppointmentsLookUpAndListing>,
    ) {
      state.init.lookup = action.payload.lookup;
      state.init.listing = action.payload.listing;
    },
    setInitializeIsLoading(state, action: PayloadAction<boolean>) {
      state.init.isLoading = action.payload;
    },
    setInitializeHasErrored(state, action: PayloadAction<boolean>) {
      state.init.hasErrored = action.payload;
    },
    setInitializeErrorMessage(state, action: PayloadAction<string | null>) {
      state.init.errorMessage = action.payload;
    },

    // === For storing Appointment Metadata for use in any flow ===

    updateAppointmentMetaFromInstitution(
      state,
      action: PayloadAction<{
        targetSystem: string | null;
        institutionCode: string | null;
      }>,
    ) {
      state.appointmentMeta.targetSystem = action.payload.targetSystem;
      state.appointmentMeta.institutionCode = action.payload.institutionCode;
    },
    updateAppointmentMetaFromService(
      state,
      action: PayloadAction<{
        visitTypeId: string | null;
        departmentCode: string | null;
        // departmentName: string | null;
        linkedServiceTypeId?: string | null;
        isHsgAppt?: boolean | null;
        clinicID?: string;
        afterCareMessage?: string | null;
        maxSlot?: number;
        HSG?: HSG;
      }>,
    ) {
      state.appointmentMeta.visitTypeId = action.payload.visitTypeId;
      state.appointmentMeta.departmentCode = action.payload.departmentCode;
      state.appointmentMeta.linkedServiceTypeId =
        action.payload.linkedServiceTypeId;
      state.appointmentMeta.isHsgAppt = action.payload.isHsgAppt ?? false;
      state.appointmentMeta.HSG = action.payload.HSG ?? {
        isHsgAppt: false,
        isHpCompleted: null,
        subsequentStartDate: null,
        subsequentEndDate: null,
      };
      // state.appointmentMeta.departmentName = action.payload.departmentName;
      state.appointmentMeta.isSameDayBooking = isSameDayCreate(
        action.payload.departmentCode,
      );
      state.appointmentMeta.clinicID = action.payload.clinicID;
      state.appointmentMeta.afterCareMessage = action.payload.afterCareMessage;
      state.appointmentMeta.maxSlot = action.payload.maxSlot;
    },
    updateAppointmentMetaForOpenAppointment(
      state,
      action: PayloadAction<{
        targetSystem: string | null;
        institutionCode: string | null;
        extensionData: string | null;
        departmentCode: string | null;
        departmentName: string | null;
        appointmentId: string | null;
        location: string | null;
        zoneInfo: string | null;
        calendarTitle: string | null;
        calendarLocation: string | null;
      }>,
    ) {
      state.appointmentMeta.targetSystem = action.payload.targetSystem;
      state.appointmentMeta.institutionCode = action.payload.institutionCode;
      state.appointmentMeta.departmentCode = action.payload.departmentCode;
      state.appointmentMeta.departmentName = action.payload.departmentName;
      state.appointmentMeta.appointmentId = action.payload.appointmentId;
      state.appointmentMeta.location = action.payload.location;
      state.appointmentMeta.zoneInfo = action.payload.zoneInfo;
      state.appointmentMeta.calendarTitle = action.payload.calendarTitle;
      state.appointmentMeta.calendarLocation = action.payload.calendarLocation;
    },
    updateAppointmentMetaAppointmentType(
      state,
      action: PayloadAction<"upcoming" | "missed" | "open" | null>,
    ) {
      state.appointmentMeta.appointmentType = action.payload;
    },

    clearAppointmentMetaState(state) {
      state.appointmentMeta = initialState.appointmentMeta;
    },

    setPopHealthStatus(
      state,
      action: PayloadAction<{
        isEnroled: boolean | null;
        enrolmentDate: string | null;
        hciCode: string | null;
        isHpCompleted: boolean | null;
        popOutMessage: string | null;
        subsequentStartDate: string | null;
        healthPlanCompletionDate: string | null;
      }>,
    ) {
      state.popHealthStatus.isEnroled = action.payload.isEnroled;
      state.popHealthStatus.enrolmentDate = action.payload.enrolmentDate;
      state.popHealthStatus.hciCode = action.payload.hciCode;
      state.popHealthStatus.isHpCompleted = action.payload.isHpCompleted;
      state.popHealthStatus.popOutMessage = action.payload.popOutMessage;
      state.popHealthStatus.subsequentStartDate =
        action.payload.subsequentStartDate;
      state.popHealthStatus.healthPlanCompletionDate =
        action.payload.healthPlanCompletionDate;
    },

    // === For the Make an Appointment Flow ===

    updateServiceForCreate(state, action: PayloadAction<string | null>) {
      state.create.selectedService = action.payload;
    },
    updateInstitutionForCreate(state, action: PayloadAction<string | null>) {
      state.create.selectedInstitution = action.payload;
    },
    updateInstitutionCodeForCreate(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.create.selectedInstitutionCode = action.payload;
    },
    updateLastVisitDateTimeForCreate(
      state,
      action: PayloadAction<Date | null>,
    ) {
      state.create.lastVisitDateTime = action.payload;
    },
    updateSlotDetailsForCreate(
      state,
      action: PayloadAction<{
        slotId: string;
        slotDateTime: string | null;
      }>,
    ) {
      state.create.selectedAvailableSlotId = action.payload.slotId;
      state.create.selectedAvailableSlotDateTime = action.payload.slotDateTime;
    },
    updateIsUrtiForCreate(state, action: PayloadAction<boolean>) {
      state.create.isUrti = action.payload;
    },
    updateSpecialtyForCreate(
      state,
      action: PayloadAction<{
        description: string;
        isFirstVisit: boolean;
        beforeCareMessage: string;
        code: string;
        visitTypeId: string;
      }>,
    ) {
      state.create.selectedSpecialty = { ...action.payload };
    },
    updateReferralForCreate(state, action: PayloadAction<string>) {
      state.create.selectedReferral = action.payload;
    },
    updatePractitionerForCreate(
      state,
      action: PayloadAction<{
        category: string;
        name: string;
        practitionerId: string;
      }>,
    ) {
      state.create.selectedPractitioner = { ...action.payload };
    },
    clearCreateAppointmentState(state) {
      state.create = initialState.create;
    },

    // === For the Make Appointment Triage Question Flow ===
    setTriageInitializeIsLoading(state, action: PayloadAction<boolean>) {
      state.triage.isLoading = action.payload;
    },
    setTriageInitializeHasErrored(state, action: PayloadAction<boolean>) {
      state.triage.hasErrored = action.payload;
    },
    setTriageInitializeErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.triage.errorMessage = action.payload;
    },
    initializeTriageQuestionList(
      state,
      action: PayloadAction<TriageQuestion[]>,
    ) {
      state.triage.questionList = action.payload;
    },
    setIsCreateFromAppointmentLanding(state, action: PayloadAction<boolean>) {
      state.create.isCreateFromAppointmentLanding = action.payload;
    },

    // === For the Reschedule an Appointment Flow ===

    updateApptDetailForReschedule(
      state,
      action: PayloadAction<{
        appointmentId: string | null;
        institutionName: string | null;
        location: string | null;
        institutionCode: string | null;
        serviceName: string | null;
        dateTime: string | null;
        slotId: string | null;
        sourceSystem: string | null;
        departmentCode: string | null;
        departmentName: string | null;
        logoCode: string | null;
        slotSearchDateRangeFrom: string | null;
        slotSearchDateRangeTo: string | null;
        isSameDayBooking: boolean;
        calendarTitle: string | null;
        calendarLocation: string | null;
        visitTypeId: string | null;
        expectedOrderDate: string | null;
        linkedAppointments: linkedAppointment[];
        rescheduleToDays?: number | null;
      }>,
    ) {
      state.appointmentMeta.appointmentId = action.payload.appointmentId;
      state.reschedule.selectedInstitution = action.payload.institutionName;
      state.reschedule.selectedService = action.payload.serviceName;
      state.reschedule.selectedDateTime = action.payload.dateTime;
      state.reschedule.selectedDepartmentName = action.payload.departmentName;
      state.appointmentMeta.institutionCode = action.payload.institutionCode;
      state.appointmentMeta.targetSystem = action.payload.sourceSystem;
      state.appointmentMeta.departmentCode = action.payload.departmentCode;
      state.appointmentMeta.departmentName = action.payload.departmentName;
      state.appointmentMeta.slotId = action.payload.slotId;
      state.appointmentMeta.logoCode = action.payload.logoCode;
      state.appointmentMeta.slotSearchDateRangeFrom =
        action.payload.slotSearchDateRangeFrom;
      state.appointmentMeta.slotSearchDateRangeTo =
        action.payload.slotSearchDateRangeTo;
      state.appointmentMeta.isSameDayBooking = action.payload.isSameDayBooking;
      state.appointmentMeta.location = action.payload.location;
      state.appointmentMeta.calendarTitle = action.payload.calendarTitle;
      state.appointmentMeta.calendarLocation = action.payload.calendarLocation;
      state.appointmentMeta.visitTypeId = action.payload.visitTypeId;
      state.appointmentMeta.expectedOrderDate =
        action.payload.expectedOrderDate;
      state.appointmentMeta.linkedAppointments =
        action.payload.linkedAppointments;
      state.appointmentMeta.rescheduleToDays = action.payload.rescheduleToDays;
    },
    updateSlotDetailsForReschedule(
      state,
      action: PayloadAction<{
        slotId: string;
        slotDateTime: string | null;
      }>,
    ) {
      state.reschedule.selectedAvailableSlotId = action.payload.slotId;
      state.reschedule.selectedAvailableSlotDateTime =
        action.payload.slotDateTime;
    },
    clearRescheduleAppointmentState(state) {
      state.reschedule = initialState.reschedule;
    },

    // === For the Reschedule LINKED Appointments Flow ===
    updateSelectedLinkedAppointment(
      state,
      action: PayloadAction<{
        appointment: Appointment;
        index: number;
      }>,
    ) {
      state.rescheduleLinkedAppointment.selectedLinkedAppointment =
        action.payload.appointment;

      state.rescheduleLinkedAppointment.selectedIndex = action.payload.index;
    },

    updateRescheduleLinkedAppointmentVisitedSlotsPage(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.rescheduleLinkedAppointment.visitedSlotsPage = action.payload;
    },

    updateSlotDateTimeList(
      state,
      action: PayloadAction<(RescheduleLinkedAppointmentNewSlot | null)[]>,
    ) {
      state.rescheduleLinkedAppointment.newSlotList = action.payload;
    },

    setSlotDetailsForRescheduleLinkedAppointment(
      state,
      action: PayloadAction<RescheduleLinkedAppointmentNewSlot>,
    ) {
      if (state.rescheduleLinkedAppointment.selectedIndex !== null) {
        state.rescheduleLinkedAppointment.newSlotList[
          state.rescheduleLinkedAppointment.selectedIndex
        ] = action.payload;
      }
    },
    setSlotDetailsForRescheduleBundledLinkedAppointment(
      state,
      action: PayloadAction<RescheduleLinkedAppointmentNewSlot[]>,
    ) {
      if (state.rescheduleLinkedAppointment.selectedIndex !== null) {
        state.rescheduleLinkedAppointment.newSlotList = action.payload;
      }
    },

    setSlotDetailsForCreateBundledLinkedAppointment(
      state,
      action: PayloadAction<CreateLinkedAppointmentNewSlot[]>,
    ) {
      state.create.createLinkedAppointment.newSlotList = action.payload;
    },

    clearRescheduleLinkedAppointmentState(state) {
      state.rescheduleLinkedAppointment =
        initialState.rescheduleLinkedAppointment;
    },

    setIsPreConfirmationModalOpen(state, action: PayloadAction<boolean>) {
      state.rescheduleLinkedAppointment.isPreConfirmationModalOpen =
        action.payload;
    },

    // === For the Register an Appointment Flow ===

    updateDetailsForActualiseAppointment(
      state,
      action: PayloadAction<{
        appointmentId: string;
        regAppointmentId: string;
        regInstitutionCode: string;
        institutionCode: string;
        sourceSystem: string | null;
        clinicCode: string | null;
        actualizeStatus?: string | null;
        queueNumber: string | null;
        institutionName: string | null;
        consultType: string | null;
        encounterId: string | null;
        appointmentTimeToArrive?: string | null;
        vcSupportStatus: string | null;
      }>,
    ) {
      state.appointmentMeta.appointmentId = action.payload.appointmentId;
      state.appointmentMeta.regAppointmentId = action.payload.regAppointmentId;
      state.appointmentMeta.regInstitutionCode =
        action.payload.regInstitutionCode;
      state.appointmentMeta.institutionCode = action.payload.institutionCode;
      state.appointmentMeta.targetSystem = action.payload.sourceSystem;
      state.appointmentMeta.clinicCode = action.payload.clinicCode;
      state.appointmentMeta.institutionName = action.payload.institutionName;
      state.appointmentMeta.consultType = action.payload.consultType;
      if (action.payload.actualizeStatus) {
        state.appointmentMeta.actualizeStatus = action.payload.actualizeStatus;
      }

      state.appointmentMeta.datetimeString =
        action.payload.appointmentTimeToArrive || "";

      state.appointmentMeta.vcSupportStatus = action.payload.vcSupportStatus;

      if (action.payload.queueNumber) {
        // if the queue number is provided, it means that this appointment has already
        // been actualized before, hence we can simply save this in the state
        if (state.register[action.payload.appointmentId] === undefined) {
          state.register[action.payload.appointmentId] =
            generateDefaultRegisteredAppt();
        }
        state.register[action.payload.appointmentId].appointmentTimeToArrive =
          action.payload.appointmentTimeToArrive || "";

        state.register[action.payload.appointmentId].queueNumber =
          action.payload.queueNumber;
        state.register[action.payload.appointmentId].institutionName =
          action.payload.institutionName;
        state.register[action.payload.appointmentId].consultType =
          action.payload.consultType;
        state.register[action.payload.appointmentId].encounterId =
          action.payload.encounterId;
      }

      // Persist state
      if (state.persist[action.payload.appointmentId] === undefined) {
        state.persist[action.payload.appointmentId] = {
          hideContactField: false,
        };
      }
    },

    updateDetailsForCheckInAppointment(
      state,
      action: PayloadAction<{
        appointmentId: string;
        institutionCode: string;
        regInstitutionCode: string;
        sourceSystem: string | null;
        clinicCode: string | null;
        queueNumber: string | null;
        institutionName: string | null;
        consultType: string | null;
        regAppointmentId: string | null;
        currentClinicCode: string | null;
        encounterId: string | null;
        appointmentTime: string | null;
      }>,
    ) {
      state.appointmentMeta.appointmentId = action.payload.appointmentId;
      state.appointmentMeta.regAppointmentId =
        action.payload.regAppointmentId || null;
      state.appointmentMeta.institutionCode = action.payload.institutionCode;
      state.appointmentMeta.regInstitutionCode =
        action.payload.regInstitutionCode;
      state.appointmentMeta.targetSystem = action.payload.sourceSystem;
      state.appointmentMeta.clinicCode = action.payload.clinicCode;
      state.appointmentMeta.institutionName = action.payload.institutionName;

      if (action.payload.queueNumber) {
        // if the queue number is provided, it means that this appointment has already
        // been actualized before, hence we can simply save this in the state
        if (state.register[action.payload.appointmentId] === undefined) {
          state.register[action.payload.appointmentId] =
            generateDefaultRegisteredAppt();
        }
        state.register[action.payload.appointmentId].queueNumber =
          action.payload.queueNumber;
        state.register[action.payload.appointmentId].institutionName =
          action.payload.institutionName;
        state.register[action.payload.appointmentId].consultType =
          action.payload.consultType;
        state.register[action.payload.appointmentId].currentClinicCode =
          action.payload.currentClinicCode;
        state.register[action.payload.appointmentId].encounterId =
          action.payload.encounterId;
        state.register[action.payload.appointmentId].appointmentTimeToArrive =
          action.payload.appointmentTime || "";
      }
    },

    updateEncounterIdForRegister(
      state,
      action: PayloadAction<{
        encounterId: string | null;
        appointmentId: string;
      }>,
    ) {
      if (state.register[action.payload.appointmentId] === undefined) {
        state.register[action.payload.appointmentId] =
          generateDefaultRegisteredAppt();
      }
      state.register[action.payload.appointmentId].encounterId =
        action.payload.encounterId;
    },

    updateQueueForActualiseAppointment(
      state,
      action: PayloadAction<{
        appointmentId: string;
        queueNumber: string | null;
        institutionName: string | null;
        consultType: string | null;
        encounterId: string | null;
        appointmentTime: string | null;
        logoCode: string | null;
      }>,
    ) {
      if (state.register[action.payload.appointmentId] === undefined) {
        state.register[action.payload.appointmentId] =
          generateDefaultRegisteredAppt();
      }
      state.register[action.payload.appointmentId].queueNumber =
        action.payload.queueNumber;
      state.register[action.payload.appointmentId].institutionName =
        action.payload.institutionName;
      state.register[action.payload.appointmentId].consultType =
        action.payload.consultType;
      state.register[action.payload.appointmentId].encounterId =
        action.payload.encounterId;
      state.register[action.payload.appointmentId].appointmentTimeToArrive =
        action.payload.appointmentTime;
      state.register[action.payload.appointmentId].logoCode =
        action.payload.logoCode;
    },

    updateQueueForRequeueAppointment(
      state,
      action: PayloadAction<{
        appointmentId: string;
        encounterId: string | null;
        queueNumber: string | null;
      }>,
    ) {
      if (state.register[action.payload.appointmentId] === undefined) {
        state.register[action.payload.appointmentId] =
          generateDefaultRegisteredAppt();
      }
      state.register[action.payload.appointmentId].queueNumber =
        action.payload.queueNumber;
      state.register[action.payload.appointmentId].encounterId =
        action.payload.encounterId;
    },

    updateCurrentClinicCode(
      state,
      action: PayloadAction<{
        appointmentId: string;
        currentClinicCode: string | null;
      }>,
    ) {
      state.register[action.payload.appointmentId].currentClinicCode =
        action.payload.currentClinicCode;
    },

    updateQueueDetailsForActualiseAppointment(
      state,
      action: PayloadAction<{
        appointmentId: string | null;
        currentStation: string | null;
        queueNumber: string | null;
        queueStatus: string | null;
        statusMessage: string | null;
        patientWaiting: string | null;
        journeyList: Journey[] | null;
        showStatusText: boolean;
        showPatientWaiting: boolean;
        canProceedToVirtualConsult: boolean;
        encounterAction: string[];
        hintMsg: string | null;
        requireCheckIn: boolean;
        ihaveArrivedMessage: string | null;
        currentClinicCode: string | null;
        meetingIdFormat: string | null;
        meetingId: string | null;
        passcode: string | null;
        lateThresholdMessage: string | null;
      }>,
    ) {
      const apptId = action.payload.appointmentId || "";
      if (state.register[apptId] === undefined) {
        state.register[apptId] = generateDefaultRegisteredAppt();
      }
      state.register[apptId].currentStation = action.payload.currentStation;
      state.register[apptId].queueNumber = action.payload.queueNumber;
      state.register[apptId].queueStatus = action.payload.queueStatus;
      state.register[apptId].statusMessage = action.payload.statusMessage;
      state.register[apptId].patientWaiting = action.payload.patientWaiting;
      state.register[apptId].journeyList = action.payload.journeyList;
      state.register[apptId].showStatusText = action.payload.showStatusText;
      state.register[apptId].showPatientWaiting =
        action.payload.showPatientWaiting;
      state.register[apptId].canProceedToVirtualConsult =
        action.payload.canProceedToVirtualConsult;
      state.register[apptId].encounterAction = action.payload.encounterAction;
      state.register[apptId].hintMsg = action.payload.hintMsg;
      state.register[apptId].requireCheckIn = action.payload.requireCheckIn;
      state.register[apptId].ihaveArrivedMessage =
        action.payload.ihaveArrivedMessage;
      state.register[apptId].currentClinicCode =
        action.payload.currentClinicCode;
      state.register[apptId].meetingId = action.payload.meetingId;
      state.register[apptId].meetingIdFormat = action.payload.meetingIdFormat;
      state.register[apptId].passcode = action.payload.passcode;
      state.register[apptId].lateThresholdMessage =
        action.payload.lateThresholdMessage;
    },
    setQueueInitializeIsLoading(
      state,
      action: PayloadAction<{ appointmentId: string; isLoading: boolean }>,
    ) {
      if (state.register[action.payload.appointmentId] === undefined) {
        state.register[action.payload.appointmentId] =
          generateDefaultRegisteredAppt();
      }
      state.register[action.payload.appointmentId].isLoading =
        action.payload.isLoading;
    },
    setQueueInitializeHasErrored(
      state,
      action: PayloadAction<{
        appointmentId: string;
        hasErrored: boolean | null;
      }>,
    ) {
      if (state.register[action.payload.appointmentId] === undefined) {
        state.register[action.payload.appointmentId] =
          generateDefaultRegisteredAppt();
      }
      state.register[action.payload.appointmentId].hasErrored =
        action.payload.hasErrored;
    },

    setQueueInitializeErrorMessage(
      state,
      action: PayloadAction<{
        appointmentId: string;
        errorMessage: string | null;
      }>,
    ) {
      if (state.register[action.payload.appointmentId] === undefined) {
        state.register[action.payload.appointmentId] =
          generateDefaultRegisteredAppt();
      }
      state.register[action.payload.appointmentId].errorMessage =
        action.payload.errorMessage;
    },
    clearRegistrationAppointmentState(state) {
      state.register = initialState.register;
    },

    // Persist
    setHideContactField(state, action: PayloadAction<boolean>) {
      if (state.persist[state.appointmentMeta.appointmentId || ""]) {
        state.persist[
          state.appointmentMeta.appointmentId || ""
        ].hideContactField = action.payload;
      }
    },

    // === For the Order Medication Flow ===
    updateAppointmentMetaForOrderMedication(
      state,
      action: PayloadAction<string>,
    ) {
      state.appointmentMeta.institutionCode = action.payload;
    },

    // === For initializing Mammogram Questionnaire

    initializeMammogramQuestions(
      state,
      action: PayloadAction<{ questions: MammogramQuestion[] }>,
    ) {
      state.mammogram.questions = action.payload.questions;
    },
    setMammogramQuestionIsLoading(state, action: PayloadAction<boolean>) {
      state.mammogram.mammogramQuestionIsLoading = action.payload;
    },
    setMammogramQuestionHasErrored(state, action: PayloadAction<boolean>) {
      state.mammogram.mammogramQuestionHasErrored = action.payload;
    },
    setMammogramQuestionErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.mammogram.mammogramQuestionErrorMessage = action.payload;
    },

    // For getSystemSettings API call
    setSystemSettingsInitializeIsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.systemSettings.isLoading = action.payload;
    },
    setSystemSettingsInitializeHasErrored(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.systemSettings.hasErrored = action.payload;
    },
    setSystemSettingsInitializeErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.systemSettings.errorMessage = action.payload;
    },
    setSystemSettingsInitializeValue(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.systemSettings.value = action.payload;
    },
    setSystemSettingsInitializeValues(
      state,
      action: PayloadAction<SettingsList>,
    ) {
      state.systemSettings.values = {
        ...state.systemSettings.values,
        ...action.payload,
      };
    },

    // For getDocumentByType API call
    setDocumentByTypeInitializeIsLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.documentByType.isLoading = action.payload;
    },
    setDocumentByTypeInitializeHasErrored(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.documentByType.hasErrored = action.payload;
    },
    setDocumentByTypeInitializeErrorMessage(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.documentByType.errorMessage = action.payload;
    },
    setDocumentByTypeInitializeValue(
      state,
      action: PayloadAction<DocumentByTypeMap>,
    ) {
      state.documentByType.documentByTypeMap = action.payload;
    },
    clearDocumentByTypeState(state) {
      state.documentByType = initialState.documentByType;
    },

    // For getAnnouncements API call
    setAnnouncementsIsLoading(state, action: PayloadAction<boolean>) {
      state.announcements.isLoading = action.payload;
    },
    setAnnouncementsHasErrored(state, action: PayloadAction<boolean>) {
      state.announcements.hasErrored = action.payload;
    },
    setAnnouncementsErrorMessage(state, action: PayloadAction<string | null>) {
      state.announcements.errorMessage = action.payload;
    },
    setAnnouncementsMessage(state, action: PayloadAction<string | null>) {
      state.announcements.announcementMsg = action.payload;
    },
    setHasAnnouncement(state, action: PayloadAction<boolean>) {
      state.announcements.hasAnnouncement = action.payload;
    },
    clearAnnouncementsState(state) {
      state.announcements = initialState.announcements;
    },

    updateCheckInSuccessState(
      state,
      action: PayloadAction<CheckInSuccessState>,
    ) {
      state.checkInSuccess = action.payload;
    },
    clearCheckInSuccessState(state) {
      state.checkInSuccess = initialState.checkInSuccess;
    },

    setTeleconsultMessage(state, action: PayloadAction<string>) {
      state.teleconsultMessage = action.payload;
    },
    setHasPromptedRegTutorial(state, action: PayloadAction<boolean>) {
      state.hasPromptedRegTutorial = action.payload;
    },
    setParentInstitutionCode(state, action: PayloadAction<string>) {
      state.appointmentMeta.parentInstitutionCode = action.payload;
    },
  },
});

export const {
  initializeAppointmentsFromMobileIntegration,
  initializeAppointments,
  setInitializeIsLoading,
  setInitializeHasErrored,
  setInitializeErrorMessage,

  updateAppointmentMetaFromInstitution,
  updateAppointmentMetaFromService,
  updateAppointmentMetaForOpenAppointment,
  updateAppointmentMetaAppointmentType,
  clearAppointmentMetaState,
  updateAppointmentMetaForOrderMedication,

  updateServiceForCreate,
  updateInstitutionForCreate,
  updateInstitutionCodeForCreate,
  updateSlotDetailsForCreate,
  updateLastVisitDateTimeForCreate,
  updateIsUrtiForCreate,
  updateSpecialtyForCreate,
  updateReferralForCreate,
  updatePractitionerForCreate,
  clearCreateAppointmentState,
  setIsCreateFromAppointmentLanding,

  setTriageInitializeIsLoading,
  setTriageInitializeHasErrored,
  setTriageInitializeErrorMessage,
  initializeTriageQuestionList,

  updateApptDetailForReschedule,
  updateSlotDetailsForReschedule,
  clearRescheduleAppointmentState,
  updateRescheduleLinkedAppointmentVisitedSlotsPage,
  updateSelectedLinkedAppointment,
  updateSlotDateTimeList,
  setSlotDetailsForRescheduleLinkedAppointment,
  setSlotDetailsForRescheduleBundledLinkedAppointment,
  setSlotDetailsForCreateBundledLinkedAppointment,
  clearRescheduleLinkedAppointmentState,
  setIsPreConfirmationModalOpen,

  updateDetailsForActualiseAppointment,
  updateQueueForActualiseAppointment,
  updateQueueForRequeueAppointment,
  updateQueueDetailsForActualiseAppointment,
  setQueueInitializeIsLoading,
  setQueueInitializeHasErrored,
  setQueueInitializeErrorMessage,
  clearRegistrationAppointmentState,
  updateCurrentClinicCode,
  setHideContactField,
  updateDetailsForCheckInAppointment,
  updateCheckInSuccessState,
  updateEncounterIdForRegister,
  clearCheckInSuccessState,

  setPopHealthStatus,

  initializeMammogramQuestions,
  setMammogramQuestionIsLoading,
  setMammogramQuestionHasErrored,
  setMammogramQuestionErrorMessage,

  setSystemSettingsInitializeIsLoading,
  setSystemSettingsInitializeHasErrored,
  setSystemSettingsInitializeErrorMessage,
  setSystemSettingsInitializeValue,
  setSystemSettingsInitializeValues,

  setDocumentByTypeInitializeIsLoading,
  setDocumentByTypeInitializeHasErrored,
  setDocumentByTypeInitializeErrorMessage,
  setDocumentByTypeInitializeValue,
  clearDocumentByTypeState,

  setAnnouncementsIsLoading,
  setAnnouncementsHasErrored,
  setAnnouncementsErrorMessage,
  setAnnouncementsMessage,
  setHasAnnouncement,
  clearAnnouncementsState,

  setTeleconsultMessage,
  setHasPromptedRegTutorial,
  setParentInstitutionCode,
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
