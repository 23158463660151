import { Box, Typography, Card } from "@mui/material";
import { ConfirmationDetailsProps } from "./ConfirmationDetails.types";
import ConfirmationCard from "../ConfirmationCard/ConfirmationCard";
import { sxStyles } from "./ConfirmationDetails.styles";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { isJHC } from "ui/appointment/helper/isJHC/isJHC";
import IMAGES from "lib/assets/images";
import { useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentDocByType,
  selectAppointmentMeta,
} from "ui/appointment/ducks/selectors";
import { resolveBeforeRescConfirmMsgDocomentType } from "api/shared/GetDocumentByType/getDocumentByType.mapper";

const ConfirmationDetails = ({
  location,
  service,
  slotDateTime,
  rescheduledSlotDateTime,
}: ConfirmationDetailsProps) => {
  const classes = sxStyles();
  const institutionCode = useAppSelector(selectAppointmentMeta).institutionCode;
  var preConsultMsgKey = "";
  if (institutionCode) {
    preConsultMsgKey = resolveBeforeRescConfirmMsgDocomentType(institutionCode);
  }
  const preConsultMsg = useAppSelector(selectAppointmentDocByType)
    .documentByTypeMap[preConsultMsgKey];

  return (
    <Box>
      {/* Top Text Section */}
      <Box sx={classes.topTextLayout}>
        <Box sx={classes.label}>
          <Typography sx={classes.labelText}>Location:</Typography>
        </Box>
        <Box sx={classes.textArea}>
          <Typography sx={classes.text} variant="subtitle1">
            {location}
          </Typography>
        </Box>
      </Box>
      <Box sx={classes.topTextLayout}>
        <Box sx={classes.label}>
          <Typography sx={classes.labelText}>Service:</Typography>
        </Box>
        <Box sx={classes.textArea}>
          <Typography sx={classes.text} variant="subtitle1">
            {service}
          </Typography>
        </Box>
      </Box>

      {/* Suggestion Text */}
      <Box sx={classes.suggestion}>
        <SharpNoticePanel bgColor="warn">
          {preConsultMsg && MapRawStringToReactElement(preConsultMsg)}
          {isJHC(institutionCode) ||
          institutionCode === "NUH" ||
          institutionCode === "AH"
            ? null
            : "Remember to remove your old appointment from your calendar."}{" "}
        </SharpNoticePanel>
      </Box>

      {/* Card Section Title */}
      <Box sx={{ ...classes.layout, ...classes.titleLayout }}>
        <Typography variant="header4_semibold" sx={classes.titleLeftText}>
          From:
        </Typography>
        <Typography variant="header4_semibold" sx={classes.titleRightText}>
          To:
        </Typography>
      </Box>

      {/* Card Section */}
      <Box sx={{ ...classes.layout, ...classes.cardSectionLayout }}>
        <Box sx={classes.cardLayout}>
          <Card sx={{ ...classes.card, ...classes.cardLeft }}>
            <ConfirmationCard slotDateTime={slotDateTime} />
            <Box
              component={"img"}
              src={IMAGES.general.ForwardIcon}
              sx={classes.arrow}
            />

            <ConfirmationCard slotDateTime={rescheduledSlotDateTime} />
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationDetails;
