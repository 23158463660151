import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getSystemSettings } from "api/shared/GetSystemSettings/getSystemSettings";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import { setShowPreventiveCare } from "ui/mhm/ducks/mhmSlice";
import {
  setSystemSettingsErrorMessage,
  setSystemSettingsHasErrored,
  setSystemSettingsIsLoading,
  setSystemSettingsLookup,
} from ".";
import { setShowMyChartMedication } from "./medrefillSlice";
import { AxiosError } from "axios";
import renderErrorMsg from "lib/util/ConsoleUtil/renderErrorMsg";
const fetchSystemSettings =
  (cluster: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSystemSettingsIsLoading(true));
      const systemSettingList = await getSystemSettings({
        category: "Medication",
        subcategory: null,
        codeName: cluster,
        institutionCode: null,
      });
      dispatch(setSystemSettingsLookup(systemSettingList.Settings));
      dispatch(setSystemSettingsHasErrored(false));
      dispatch(setSystemSettingsErrorMessage(null));
    } catch (error) {
      console.error(renderErrorMsg(error));
      dispatch(setSystemSettingsLookup([]));
      dispatch(setSystemSettingsHasErrored(true));
      if (error instanceof AxiosError) {
        dispatch(setSystemSettingsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setSystemSettingsIsLoading(false));
    }
  };

const fetchMyChartSystemSettings =
  () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch(setSystemSettingsIsLoading(true));
      const systemSettingList = await getSystemSettings({
        category: "OneNUHs",
        subcategory: "AppServicesAndUsefulLinks",
        codeName: "FeatureToggle",
        institutionCode: null,
      });

      const rawValue = systemSettingList.Settings[0].Value;

      const parsedValueArray: { name: string; toggle: boolean }[] =
        parseJson(rawValue);

      const MedicationRecordToggle = parsedValueArray.find(
        (obj) => obj.name === "View Medication Records",
      );

      const PreventiveCareToggle = parsedValueArray.find(
        (obj) => obj.name === "Preventive Care",
      );

      dispatch(
        setShowMyChartMedication(
          MedicationRecordToggle ? MedicationRecordToggle.toggle : false,
        ),
      );

      dispatch(
        setShowPreventiveCare(
          PreventiveCareToggle ? PreventiveCareToggle.toggle : false,
        ),
      );
      dispatch(setSystemSettingsHasErrored(false));
      dispatch(setSystemSettingsErrorMessage(null));
    } catch (error) {
      dispatch(setShowMyChartMedication(false));
      dispatch(setSystemSettingsHasErrored(true));

      if (error instanceof AxiosError) {
        dispatch(setSystemSettingsErrorMessage(error.response?.data.Message));
      }
    } finally {
      dispatch(setSystemSettingsIsLoading(false));
    }
  };

export { fetchMyChartSystemSettings, fetchSystemSettings };
