import { useState } from "react";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowser,
  navigateToExternalInMobileBrowserForStandardPage,
} from "lib/routing/navigate/navigate";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import IMAGES from "lib/assets/images";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { formatSharingUrl } from "lib/mobileIntegration/shareUrl/formatSharingUrl";
import { VCPQueueDetailProps } from "./VCPQueueDetail.types";
import { sxStyles } from "./VCPQueueDetail.styles";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import VCPLink from "../../VCPLink/VCPLink";
import { PATHS } from "lib/routing/paths/paths";
import { useNavigate } from "react-router-dom";
import { requeueForConsultation } from "api/encounter/RequeueForConsultation/requeueForConsultation";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { setCurrentFlowAction } from "lib/redux/navigation";
import { useAppDispatch } from "lib/redux/hooks";
import { handleErrorModal } from "lib/redux/notifications";
import {
  updateAppointmentMetaAppointmentType,
  updateQueueForRequeueAppointment,
} from "ui/appointment/ducks/appointmentsSlice";
import { updateApptDetailForReschedule } from "ui/appointment/ducks";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import { AxiosError } from "axios";
import { medrefillUrl } from "lib/configs/configs/config";

const VCPQueueDetail = ({
  appointment,
  currentAppointmentTime,
  refreshAt,
  showPatientWaiting,
  patientWaiting,
  institutionName,
  institutionCode,
  regInstitutionCode,
  appointmentId,
  memberIdentifier,
  canProceedToVirtualConsult,
  participantURL,
  encounterStatusCode,
  encounterStatus,
  vcErrorMsg,
  isLoggingProceedToVideoConsultRequired,
  isShowShareVCLinks,
  encounterAction,
  hintMsg,
  meetingId,
  passcode,
  clinicCode,
  onRefreshAll,
}: VCPQueueDetailProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = sxStyles(encounterStatusCode || null);

  const [isLoading, setIsLoading] = useState(false);

  const currentApptDateTimeDisplay = formatDate(
    formatDateToISO(currentAppointmentTime),
    formatOptions.dayMonthYearTime,
  );

  const { regAppointmentId } = appointment;

  const isVirtualConsultEnded = encounterStatusCode === "ENDED";

  const isValidZoomURL = participantURL ? true : false;

  const [pageFlag, setPageFlag] = useState(false);

  const ORDER_MED_DISCLAIMER = hintMsg;
  // "Medication may have been prescribed for you. Tap on “Order Medication” to arrange delivery or collection.";

  const handleClickOrderMedication = () => {
    // fire mobile orderMed action with institutionCode so that mobile client can extract the institutionCode to pass to redux state when starting order medication flow
    navigateToExternalInMobileBrowserForStandardPage(medrefillUrl(), "ss", {
      institutionCode,
      date: formatDate(
        formatDateToISO(currentAppointmentTime),
        formatOptions.yearMonthDay,
      ),
      order: "vc",
    });
  };

  const onRequeueForConsultation = async () => {
    setIsLoading(true);
    try {
      const requeueAppointmentPayload = await requeueForConsultation(
        memberIdentifier,
        regAppointmentId ? regAppointmentId : appointmentId,
        regInstitutionCode,
        institutionCode,
        clinicCode,
      );

      dispatch(
        updateQueueForRequeueAppointment({
          appointmentId: appointmentId ?? "",
          queueNumber: requeueAppointmentPayload.QueueNumber,
          encounterId: requeueAppointmentPayload.EncounterId,
        }),
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          handleErrorModal({
            open: true,
            message: error.response?.data.Message,
          }),
        );
      } else {
        dispatch(handleErrorModal({ open: true, message: null }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Just overwrite isLoggingProceedToVideoConsultRequired=false to not to call attendConsultation API as per Sudha's request on 4th Feb '22
  isLoggingProceedToVideoConsultRequired = false;

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box sx={classes.vcpInstitutionNameBox}>
            <Typography sx={classes.contentTitle}>{institutionName}</Typography>
          </Box>
          <Box>
            <Typography sx={classes.currentStationText}>
              Video Consult Appointment
            </Typography>
            <Typography sx={classes.contentText}>
              {currentApptDateTimeDisplay}
            </Typography>
            {meetingId !== null &&
              meetingId &&
              passcode &&
              passcode !== null &&
              !isVirtualConsultEnded &&
              isShowShareVCLinks && (
                <>
                  <Typography sx={classes.consultDetailsTitle}>
                    Video Consult Details:
                  </Typography>
                  <Box>
                    <Typography sx={classes.consultDetails}>
                      Zoom Meeting ID:{" "}
                      {
                        <Typography component={"span"} sx={classes.consultId}>
                          {" "}
                          {meetingId}{" "}
                        </Typography>
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={classes.consultDetails}>
                      Zoom Passcode:{" "}
                      {
                        <Typography component={"span"} sx={classes.consultId}>
                          {" "}
                          {meetingId}{" "}
                        </Typography>
                      }
                    </Typography>
                  </Box>
                </>
              )}
          </Box>

          {!isVirtualConsultEnded ? (
            <>
              {/* Share zoom link  */}
              {isShowShareVCLinks ? (
                isValidZoomURL ? (
                  <Box my={2}>
                    <VCPLink
                      iconSrc={IMAGES.general.Share}
                      iconAlt="Share my video consult details"
                      linkDisplayText="Share my video consult details"
                      handleClick={() => {
                        logEventToGoogleAnalytics(
                          EVENTS.CLICK_APPT_SHARE_VIDEO_LINK,
                        );
                        mobileNavigate(formatSharingUrl(participantURL || ""));
                      }}
                    />
                  </Box>
                ) : (
                  <SharpNoticePanel bgColor="extremeWarn">
                    {vcErrorMsg}
                  </SharpNoticePanel>
                )
              ) : null}

              <Box display="flex">
                <Box sx={classes.cardDivider}>
                  <Box sx={classes.queueFooterBox}>
                    <Box sx={classes.queueFooterBoxSpace}>
                      <Typography sx={classes.detailLabel}>
                        Refreshed at
                      </Typography>
                      <Typography sx={classes.refreshedText}>
                        {refreshAt ??
                          formatDate(
                            formatDateToISO(new Date()),
                            formatOptions.timeWithPeriod,
                          ).toLowerCase()}
                      </Typography>
                    </Box>

                    <Box sx={{ ...classes.queueStatusBox, textAlign: "right" }}>
                      <Typography sx={classes.detailLabel}>Status</Typography>
                      <Typography sx={classes.queueStatusText}>
                        {encounterStatus}
                      </Typography>
                    </Box>

                    {showPatientWaiting && patientWaiting !== null ? (
                      encounterStatusCode !== "NOSHOW" && "ENDED" ? (
                        <Box
                          sx={{
                            ...classes.queueFooterBoxSpace,
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={classes.patientWaitingText}>
                            <Typography
                              component={"span"}
                              sx={classes.patientQueueNumber}
                            >
                              {patientWaiting}
                            </Typography>{" "}
                            Patient(s) before you
                          </Typography>
                        </Box>
                      ) : null
                    ) : null}
                  </Box>

                  {canProceedToVirtualConsult ? (
                    isValidZoomURL ? (
                      <Box>
                        <Button
                          sx={classes.button}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            if (isLoggingProceedToVideoConsultRequired) {
                              setPageFlag(!pageFlag);
                            }
                            navigateToExternalInMobileBrowser(
                              participantURL || "",
                            );
                          }}
                        >
                          Start video consult
                        </Button>
                      </Box>
                    ) : null
                  ) : null}

                  <Box>
                    {encounterAction.includes("REQUEUE") && (
                      <Box sx={{ mb: 2 }}>
                        <Button
                          sx={classes.button}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            onRequeueForConsultation();
                            onRefreshAll();
                          }}
                        >
                          Queue again
                        </Button>
                      </Box>
                    )}
                    <Box>
                      {encounterAction.includes("OFFLINERESCHEDULE") && (
                        <Button
                          sx={classes.button}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setPageFlag(!pageFlag);
                            dispatch(
                              setCurrentFlowAction("APPOINTMENT_RESCHEDULE"),
                            );
                            dispatch(
                              updateApptDetailForReschedule({
                                appointmentId: appointment.appointmentId,
                                institutionName: appointment.institutionName,
                                location: appointment.location,
                                serviceName: appointment.serviceDisplayName,
                                dateTime: formatDateToISO(appointment.datetime),
                                institutionCode: appointment.institutionCode,
                                sourceSystem: appointment.sourceSystem,
                                departmentCode: appointment.consultType,
                                departmentName: appointment.departmentName,
                                logoCode: appointment.logoCode,
                                slotSearchDateRangeFrom:
                                  appointment.slotSearchDateRangeFrom,
                                slotId: appointment.slotId,
                                slotSearchDateRangeTo:
                                  appointment.slotSearchDateRangeTo,

                                isSameDayBooking: isSameDayReschedule(
                                  appointment.consultType,
                                  appointment.logoCode,
                                ),
                                calendarTitle: appointment.calendarTitle,
                                calendarLocation: appointment.calendarLocation,
                                visitTypeId: appointment.visitTypeId,

                                expectedOrderDate: null, //VCP does not use EPD date
                                linkedAppointments:
                                  appointment.linkedAppointments !== null
                                    ? [
                                        ...appointment.linkedAppointments.map(
                                          (appt) => {
                                            return {
                                              appointmentId: appt.appointmentId,
                                              regAppointmentId:
                                                appt.regAppointmentId,
                                            };
                                          },
                                        ),
                                      ]
                                    : [],
                              }),
                            );
                            dispatch(
                              updateAppointmentMetaAppointmentType("upcoming"),
                            );
                            navigate(PATHS.APPOINTMENT_RESCHEDULE_FORM.path);
                          }}
                        >
                          Reschedule appointment
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box display="flex" mt={1}>
              <Box sx={classes.cardDivider}>
                <Box sx={classes.queueFooterBox}>
                  {ORDER_MED_DISCLAIMER !== null ? (
                    <SharpNoticePanel bgColor="extremeWarn">
                      {ORDER_MED_DISCLAIMER}
                    </SharpNoticePanel>
                  ) : null}
                  <Box>
                    <Box sx={classes.statusContainer}>
                      <Typography sx={classes.statusTitle}>Status</Typography>
                      <Typography sx={classes.statusInfo}>
                        {encounterStatus}
                      </Typography>
                    </Box>

                    {encounterAction.includes("ORDERMED") && (
                      <Box sx={classes.orderMedicationContainer}>
                        <IconTextButton
                          icon={IMAGES.medrefill.OrderMedicationIcon}
                          onClick={handleClickOrderMedication}
                          label={["Order", "medication"]}
                          ariaLabel="Order medication"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default VCPQueueDetail;
