import { getEnvValue } from "../helpers/configHelper";
import {
  REACT_APP_ENV,
  REACT_APP_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_PRESCRIBED_MEDICATION_URL,
  REACT_APP_CROSS_CLUSTER_URL,
  REACT_APP_API_API_KEY,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASEURL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_AUTOPAY_ZOOM_URL,
  REACT_APP_SCREEN_FOR_LIFE_BACK_URL,
  REACT_APP_MEDREFILL_URL,
  REACT_APP_MAMMOGRAM_SURVEY_URL,
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_AUTH_SCOPE,
  REACT_APP_AUTH_CLIENT_ID,
} from "./configKeys";

/**
 * Configuration Handlers
 *
 * Responsible for obtaining values from environment variables and parsing
 * them into a format that's immediately consumable by the application
 */

const ENV_LOC = "loc";
const ENV_DEV = "dev";
const ENV_DEVSIT = "devsit";
const ENV_DEVUAT = "devuat";
const ENV_SIT = "sit";
const ENV_UAT = "uat";
const ENV_PRD = "prd";

const appEnv = (): string => {
  const env = getEnvValue(REACT_APP_ENV);

  switch (env) {
    case ENV_LOC:
      return ENV_LOC;
    case ENV_DEV:
      return ENV_DEV;
    case ENV_DEVSIT:
      return ENV_DEVSIT;
    case ENV_DEVUAT:
      return ENV_DEVUAT;
    case ENV_SIT:
      return ENV_SIT;
    case ENV_UAT:
      return ENV_UAT;
    case ENV_PRD:
      return ENV_PRD;
    default:
      // always default to PRD for safety, for example if someone mis-configures the
      // environment variable, we can recover by ensuring configs are PROD-safe
      return ENV_PRD;
  }
};

const isDeveloperModeAppEnv = (): boolean => {
  const env = getEnvValue(REACT_APP_ENV);

  return (
    env === ENV_LOC ||
    env === ENV_DEV ||
    env === ENV_DEVSIT ||
    env === ENV_DEVUAT
  );
};

/**
 * Obtains the React Web Application's Environment Name from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const appUrl = (): string => {
  return getEnvValue(REACT_APP_URL);
};

/**
 * Obtains the API's base URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const apiBaseUrl = (): string => {
  return getEnvValue(REACT_APP_API_BASE_URL);
};

/**
 * Obtains the Prescribed Medication standard page's URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const prescribedMedicationUrl = (): string => {
  return getEnvValue(REACT_APP_PRESCRIBED_MEDICATION_URL); // this url isn't actively used by mobile. it is only a dummy placeholder
};

/**
 * Obtains the Cross Cluster Appointment's URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const crossClusterUrl = (): string => {
  return getEnvValue(REACT_APP_CROSS_CLUSTER_URL);
};

/**
 * Obtains Payment AutoPay URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const autopayUrl = (): string => {
  return getEnvValue(REACT_APP_AUTOPAY_ZOOM_URL);
};

/**
 * Obtains Screen for Life URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const screenForLifeBackUrl = (): string => {
  return getEnvValue(REACT_APP_SCREEN_FOR_LIFE_BACK_URL);
};

/**
 * Obtains medRefill URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const medrefillUrl = (): string => {
  return getEnvValue(REACT_APP_MEDREFILL_URL);
};

/**
 * Obtains Mammogram URL from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const mammogramUrl = (): string => {
  return getEnvValue(REACT_APP_MAMMOGRAM_SURVEY_URL);
};

/**
 * Obtains the API's API key from environment variables
 *
 * @throws  Throws an error if this variable could not be found
 */
const appApiKey = (): string => {
  return getEnvValue(REACT_APP_API_API_KEY);
};

// Get Authentication config
const authClientId = (): string => {
  return getEnvValue(REACT_APP_AUTH_CLIENT_ID);
};

const authRedirectURI = (): string => {
  return getEnvValue(REACT_APP_AUTH_REDIRECT_URI);
};

const authScope = (): string => {
  return getEnvValue(REACT_APP_AUTH_SCOPE);
};

const authAuthority = (): string => {
  return getEnvValue(REACT_APP_AUTH_AUTHORITY);
};

const authClientSecret = (): string => {
  return getEnvValue(REACT_APP_AUTH_CLIENT_SECRET);
};
/**
 * It returns an object with the values of the environment variables that are needed to initialize
 * Firebase
 * @returns An object with the values of the environment variables.
 */
const firebaseConfigValues = () => {
  return {
    apiKey: getEnvValue(REACT_APP_FIREBASE_API_KEY),
    authDomain: getEnvValue(REACT_APP_FIREBASE_AUTH_DOMAIN),
    databaseURL: getEnvValue(REACT_APP_FIREBASE_DATABASEURL),
    projectId: getEnvValue(REACT_APP_FIREBASE_PROJECT_ID),
    storageBucket: getEnvValue(REACT_APP_FIREBASE_STORAGE_BUCKET),
    messagingSenderId: getEnvValue(REACT_APP_FIREBASE_MESSAGING_SENDER_ID),
    appId: getEnvValue(REACT_APP_FIREBASE_APP_ID),
    measurementId: getEnvValue(REACT_APP_FIREBASE_MEASUREMENT_ID),
  };
};

export {
  ENV_LOC,
  ENV_DEV,
  ENV_DEVSIT,
  ENV_DEVUAT,
  ENV_SIT,
  ENV_UAT,
  ENV_PRD,
  appEnv,
  isDeveloperModeAppEnv,
  appUrl,
  apiBaseUrl,
  prescribedMedicationUrl,
  autopayUrl,
  crossClusterUrl,
  screenForLifeBackUrl,
  medrefillUrl,
  mammogramUrl,
  appApiKey,
  firebaseConfigValues,
  authClientId,
  authRedirectURI,
  authScope,
  authAuthority,
  authClientSecret,
};
