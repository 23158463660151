import { JourneyCardProps } from "./JourneyCard.types";
import { Box, Typography } from "@mui/material";
import { sxStyles } from "./JourneyCard.styles";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

const JourneyCard = ({
  isActive,
  status,
  journeyNumber,
  locationTitle,
  dateTime,
  contrastText,
}: JourneyCardProps) => {
  const classes = sxStyles();

  return (
    <>
      <Box
        sx={
          isActive
            ? {
                ...classes.journeyCardActiveBox,
                ...classes.journeyCardBox,
              }
            : {
                ...classes.journeyCardNonActiveBox,
                ...classes.journeyCardBox,
              }
        }
      >
        <Box
          sx={
            isActive
              ? classes.journeyActiveNumberBox
              : classes.journeyNonActiveNumberBox
          }
        >
          <Typography sx={classes.journeyNumber}>{journeyNumber}</Typography>
        </Box>
        <Box sx={classes.journeyTextBox}>
          {locationTitle && (
            <Typography sx={classes.journeyCardActiveText}>
              {locationTitle}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={classes.journeyCardActiveDateTime}>
              {dateTime
                ? formatDate(
                    convertTime(dateTime),
                    formatOptions.timeWithPeriod,
                  )
                : ""}
            </Typography>

            <Typography sx={classes.journeyCardActiveStatus}>
              {status}
            </Typography>
          </Box>

          {contrastText && (
            <Typography sx={classes.contrastText}>{contrastText}</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

// This code block will convert the time string ("02:15 PM") to an ISO string ("2024-04-19T14:15:00")
const convertTime = (time: string | null) => {
  if (time) {
    // dateTime format is received as "02:15 PM"
    const timeParts = time.split(":");
    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1].split(" ")[0]);
    const period = timeParts[1].split(" ")[1];

    // Adjust hours if it's PM
    if (period === "PM" && hours < 12) {
      hours += 12;
    }

    // Create a new Date object
    const dateObj = new Date();
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);

    return formatDateToISO(dateObj);
  }
  return "";
};

export const exportForTesting = {
  convertTime,
};

export default JourneyCard;
