import { SYSTEM_DEVICE_PLATFORM_ANDROID } from "lib/mobileIntegration/constants";
import { store } from "lib/redux/root";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Scrolls to the top of the browser web view, when a route changes.
 * (recommended approach by the react-router documentation)
 *
 * === Note to developers:
 * Scrolls to the top of the BROWSER WEB VIEW'S global window, however, if a child component
 * wraps the page and has a style that forces the scroll to be preserved, this method will
 * not work and scrolling must be handled within that child component, additionally.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();
  const devicePlatform = store.getState().system.devicePlatform;
  useEffect(() => {
    if (devicePlatform === SYSTEM_DEVICE_PLATFORM_ANDROID) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname, devicePlatform]);

  return <></>;
};

export default ScrollToTop;
