import { FormControl, Typography, FormLabel, Box } from "@mui/material";
import { sxStyles } from "../../AppointmentForm.styles";
import { CancelApptFormFieldProps } from "./CancelApptFormField.types";
import nameof from "ts-nameof.macro";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";
import { useAppSelector } from "lib/redux/hooks";
import { selectAppointments } from "ui/appointment/ducks/selectors";
import { CANCELLATION_REASON_LIST_KEY } from "api/shared/GetDocumentByType/getDocumentByType.mapper";

const CancelApptFormField = ({
  name,
  currentApptDateTime,
  cancelApptFormField,
  handleChange,
}: CancelApptFormFieldProps) => {
  const classes = sxStyles();
  const cancellationReasonListValue =
    useAppSelector(selectAppointments).documentByType.documentByTypeMap[
      CANCELLATION_REASON_LIST_KEY
    ];
  const cancellationReasonList = cancellationReasonListValue
    ? cancellationReasonListValue.split("|")
    : [];
  const mapCancellationReason = cancellationReasonList.map((reason) => {
    return {
      id: reason,
      text: reason,
    };
  });

  return (
    <>
      <Box sx={classes.layout}>
        <Typography variant="subtitle1" sx={classes.intensionText}>
          I want to <strong>cancel</strong> this appointment at:
        </Typography>
        <Typography variant="subtitle1" sx={classes.intensionLocationText}>
          {name}
        </Typography>
      </Box>
      <FormControl component="fieldset" fullWidth>
        <FormLabel sx={classes.currentDateTimeformLabel}>
          Current Appointment
        </FormLabel>

        <Box sx={classes.apptDateTimeField}>
          <Box>
            <Typography variant="subtitle1" sx={classes.intensionText}>
              Date:
            </Typography>
            <Typography variant="subtitle1" sx={classes.intensionLocationText}>
              {currentApptDateTime}
            </Typography>
          </Box>
        </Box>
      </FormControl>
      <Box sx={classes.textBox}>
        <Dropdown
          name={nameof(cancelApptFormField.reasonForCancel)}
          label="Reason for Cancellation"
          value={
            mapCancellationReason.find(
              (reasonObject) =>
                reasonObject.text === cancelApptFormField.reasonForCancel,
            )?.id
          }
          items={mapCancellationReason}
          handleChange={handleChange}
        />
      </Box>

      <FormControl component="fieldset" fullWidth>
        <MultiLineTextField
          name={nameof(cancelApptFormField.remarks)}
          placeholder="Remarks"
          value={cancelApptFormField.remarks}
          rowNumber={3}
          maxNumber={5}
          maxCharLength={200}
          handleChange={handleChange}
        />
      </FormControl>
    </>
  );
};

export default CancelApptFormField;
