import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { sxStyles } from "./AddedBillDetail.styles";
import IMAGES from "lib/assets/images";
import ProfileHeaderContainer from "../../common/ProfileHeader/ProfileHeaderContainer";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import {
  MAX_AMOUNT_PAYABLE_PER_TRANSACTION,
  MAX_DIGIT_OF_FRACTIONAL_PART,
  MAX_DIGIT_OF_WHOLE_NUMBER_PART,
  TRANSACTION_LIMIT_ERROR,
} from "../../Constants";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { ViewManualBill } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import { PaymentBillsToPayState } from "ui/payment/ducks/payment.types";
import { deleteAddedBillByInvNo, setBillsToPay } from "ui/payment/ducks";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectPayments } from "ui/payment/ducks/selectors";

const formatAmount = (
  billsToPay: PaymentBillsToPayState[],
  billDetails: ViewManualBill | null | undefined,
) => {
  let amountToPayStateValue: number | null;
  if (billsToPay.length === 0) {
    // don't have saved input on bills to pay yet in this flow
    amountToPayStateValue = null;
  } else {
    // only one bill will be present in this flow if billsToPay has element
    const rawAmountToPay = billsToPay[0].AmountToPay;
    amountToPayStateValue = Number(rawAmountToPay);
  }

  const amount =
    amountToPayStateValue !== null
      ? amountToPayStateValue
      : Number(billDetails?.PaymentAmount) || null;
  return formatPayment(amount).slice(2);
};

const AddedBillDetail = () => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  const billInvoiceNumber =
    useAppSelector(selectPayments).addedBills.selectedAddedBillsIndex;
  const billDetails =
    useAppSelector(selectPayments).addedBills.allAddedBills.find(
      (bill) => bill.InvoiceNumber === billInvoiceNumber,
    ) || null;

  let dateTimeObj: Date | null = null;
  dateTimeObj = getDateFromISOString(billDetails?.BillDate || null);

  const amountDue = billDetails?.AmountDueFromPatient ?? 0;
  const [amountToPay, setAmountToPay] = useState<string | null>(null);
  const billsToPay = useAppSelector(selectPayments).billsToPay;

  // log page to GA on mount
  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_ADDED_BILL_DETAILS);
  }, []);

  // TODO: change useEffect to function approach
  useEffect(() => {
    const formattedAmount = formatAmount(billsToPay, billDetails);
    setAmountToPay(formattedAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billDetails]);

  const disablePayment = () => {
    if (amountToPay && billDetails) {
      const payment = Number(amountToPay);
      if (
        (amountDue === 0 && payment <= MAX_AMOUNT_PAYABLE_PER_TRANSACTION) ||
        (payment <= amountDue && payment > 0)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  // Delete selected added bill(s) Warning Dialog open & close state and handlers
  const [open, setOpen] = useState(false);

  return (
    <>
      <ProfileHeaderContainer title="'s Added Bills" />

      <>
        <Box sx={classes.card}>
          <Box sx={classes.cardHeader}>
            <Typography sx={classes.cardMainTitle}>
              Final Amount Payable
            </Typography>
            <Typography sx={classes.cardAmountValue}>
              {formatPayment(amountDue)}
            </Typography>
          </Box>
          <Box sx={classes.cardDetailWrapper}>
            <Box sx={classes.cardHeadDetails}>
              <Box sx={classes.cardLeft}>
                <Typography sx={classes.cardLabel}>
                  Bill Date
                  <Typography sx={classes.cardDate}>
                    {dateTimeObj
                      ? formatDate(formatDateToISO(dateTimeObj), "dd MMM yyyy")
                      : null}
                  </Typography>
                </Typography>
              </Box>
              <Box sx={classes.cardRight}>
                <IconTextButton
                  icon={IMAGES.general.DeleteIcon}
                  label={["Delete bill"]}
                  onClick={() => {
                    setOpen(true);
                  }}
                  ariaLabel={"Delete bill"}
                />
              </Box>
            </Box>
            <Box py={1}>
              <Box sx={classes.cardDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardTitle}>Summary</Typography>
                </Box>
              </Box>
              <Box sx={classes.cardDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardLabel}>Bill for</Typography>
                </Box>
                <Box sx={classes.cardRight}>
                  <Typography sx={classes.cardTitle}>
                    {billDetails?.InstitutionName}
                  </Typography>
                </Box>
              </Box>
              <Box sx={classes.cardDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardLabel}>Patient name</Typography>
                </Box>
                <Box sx={classes.cardRight}>
                  <Typography sx={classes.cardTitle}>
                    {billDetails?.PatientName}
                  </Typography>
                </Box>
              </Box>
              <Box sx={classes.cardDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardLabel}>
                    Bill Reference No
                  </Typography>
                </Box>
                <Box sx={classes.cardRight}>
                  <Typography sx={classes.cardTitle}>
                    {billDetails?.InvoiceNumber}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={classes.cardFooter}>
              <Box sx={classes.cardLeft}>
                <Typography sx={classes.cardTitle}>Amount to Pay:</Typography>
              </Box>
              <Box sx={classes.cardRight}>
                <Box sx={classes.cardValueBox}>
                  $
                  <SingleLineTextField
                    type="decimal"
                    decimalPlaces={MAX_DIGIT_OF_FRACTIONAL_PART}
                    maxCharLength={MAX_DIGIT_OF_WHOLE_NUMBER_PART}
                    value={amountToPay}
                    disabled={false}
                    required={false}
                    error={
                      Number(amountToPay) > MAX_AMOUNT_PAYABLE_PER_TRANSACTION
                    }
                    errorText={TRANSACTION_LIMIT_ERROR}
                    handleChange={(event) => setAmountToPay(event.target.value)}
                  ></SingleLineTextField>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.cardButtons}>
          <Button
            variant="contained"
            color="primary"
            sx={classes.fullButton}
            disabled={disablePayment()}
            onClick={() => {
              dispatch(
                setBillsToPay([
                  {
                    InvNo: billDetails?.InvoiceNumber ?? "",
                    Selected: true,
                    AmountToPay: amountToPay ?? "0",
                    AmountDue: amountToPay ?? "0",
                  },
                ]),
              );

              navigate(PATHS.PAYMENT_PAYOR_INFO.path);
            }}
          >
            Make payment
          </Button>
        </Box>
        <ConfirmationModal
          open={open}
          title="Are you sure?"
          body={
            <>
              Bill <b>Reference No. {billDetails?.InvoiceNumber}</b> will be
              deleted.
            </>
          }
          nextButtonText="Delete"
          cancelButtonText="Cancel"
          onClose={() => setOpen(false)}
          onClickNext={() => {
            dispatch(deleteAddedBillByInvNo(billDetails?.InvoiceNumber ?? ""));
            navigate(PATHS.PAYMENT_MOBILE.path);
          }}
          onClickCancel={() => setOpen(false)}
        />
      </>
    </>
  );
};

export default AddedBillDetail;
