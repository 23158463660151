/* istanbul ignore file */
// Root-level configurations or initializations
import { enableConsoleLoggingForAllowedEnvironments } from "lib/util/ConsoleUtil/deactivate";
import { configureFirebaseAnalytics } from "lib/util/GoogleAnalyticsUtil/setup";
import { initializeUnauthorizedHttpResponseInterceptor } from "api/resources/handlers/interceptors/unauthorizedResponseInterceptor";
import {
  mobileInit,
  renderReactOnDOM,
  androidBackNav,
} from "./index.initialize";

// Root-level CSS
import "./index.css";
import { initializeMessageChannel } from "lib/routing/messageChannel/initializeMessageChannel";

// ==================
// INITIALIZATION
// ==================

enableConsoleLoggingForAllowedEnvironments();
configureFirebaseAnalytics();
initializeUnauthorizedHttpResponseInterceptor();
export const { sendIOSMessage } = initializeMessageChannel.iOS();

// ==================
// MOBILE INTEGRATION FUNCTIONS
// ==================
declare global {
  interface Window {
    mobileInit: (payload: string) => boolean;
    androidBackNav: () => void;
  }
}

window.mobileInit = mobileInit;
window.androidBackNav = androidBackNav;

renderReactOnDOM();
