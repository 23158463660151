import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import HeaderBlock from "../../components/HeaderBlock/HeaderBlock";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateReferralForCreate } from "ui/appointment/ducks/appointmentsSlice";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useEffect, useState } from "react";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import {
  selectAppointments,
  selectCreateAppointment,
} from "ui/appointment/ducks/selectors";
import { ACTIONS, PATHS } from "lib/routing";
import ReferralBlock from "../../components/ReferralBlock/ReferralBlock";
import { REFERRED_BY } from "api/appointment/CreateAppointment/FormData/createAppointmentRequestFormData";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ErrorModalTitles } from "lib/components/navigation/AppRouteRenderer/ErrorModal/HelperFunctions";

const ReferralSelectionPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Redux States
  const {
    selectedInstitution,
    selectedSpecialty,
    selectedReferral,
    selectedInstitutionCode,
  } = useAppSelector(selectCreateAppointment);
  const { isLoading, listing, hasErrored } =
    useAppSelector(selectAppointments).init;

  // Local States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);

  const hasExistingFV = listing.upcoming
    ?.filter((appt) => appt.institutionCode === selectedInstitutionCode)
    .some((appt) => appt.visitTypeId === selectedSpecialty?.visitTypeId);

  const isReferralBySelf = selectedReferral === REFERRED_BY[0].value;

  // Reset referral state
  useEffect(() => {
    dispatch(updateReferralForCreate(""));
  }, [dispatch]);

  return (
    <>
      {isReferralBySelf && isLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <HeaderBlock location={selectedInstitution} />

          <Box
            sx={{
              m: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Box sx={{ mt: 3, mb: 4 }}>
                <SingleLineTextField
                  value={selectedSpecialty?.description}
                  placeholder="Specialty"
                  disabled
                />
              </Box>

              <ReferralBlock />
            </Box>

            <Stack gap={2}>
              <Button
                disabled={!selectedReferral}
                onClick={() => {
                  // !exp: Business logic to display popup when referral is "Myself"
                  // Deny FV creation if there is an upcoming appointment with the same specialty or getApptList failed
                  if (isReferralBySelf) {
                    if (hasExistingFV || hasErrored) {
                      setIsDenyModalOpen(true);
                      return;
                    }
                    setIsModalOpen(true);
                    return;
                  }

                  navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
                }}
              >
                Next
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </Stack>
          </Box>
        </Box>
      )}

      {/* Proceed to book FV */}
      <ConfirmationModal
        title="Book Private Appointment"
        open={isModalOpen}
        body={MapRawStringToReactElement(
          selectedSpecialty?.beforeCareMessage ?? "",
        )}
        nextButtonText="Proceed"
        onClickNext={() => {
          setIsModalOpen(false);
          navigate(PATHS.APPOINTMENT_CREATE_CONSULTANT.path);
        }}
        cancelButtonText="Cancel"
        onClickCancel={() => setIsModalOpen(false)}
      />

      {/* Deny FV creation or errored getAppointmentList */}
      <ConfirmationModal
        title={ErrorModalTitles.oops}
        open={isDenyModalOpen}
        body={MapRawStringToReactElement(
          hasExistingFV
            ? "You already have this appointment scheduled with this institution."
            : "This service is not available at the moment. Please try again later.",
        )}
        nextButtonText={hasExistingFV ? "Go to Appointment" : "Back to Home"}
        onClickNext={() => {
          setIsDenyModalOpen(false);
          hasExistingFV
            ? navigate(PATHS.APPOINTMENT_MOBILE.path)
            : mobileNavigate(ACTIONS.BACK_ACTION);
        }}
        hideCancelButton
      />
    </>
  );
};

export default ReferralSelectionPage;
