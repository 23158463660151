import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { color } from "./color";
import { size } from "./size";
import consoleSplash from "lib/theme/consoleSplash";
import { fontWeights, typography } from "./typography";
import { REACT_APP_ENV } from "lib/configs/configs/configKeys";
import { getEnvValue } from "lib/configs/helpers/configHelper";

declare module "@mui/material/styles" {
  interface CustomTheme {
    IHADisabled: {
      disabled?: {};
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

const {
  title_bold,
  header1_regular,
  header1_semibold,
  header1_bold,
  header2_regular,
  header2_semibold,
  header2_bold,
  header3_regular,
  header3_semibold,
  header3_bold,
  header4_regular,
  header4_semibold,
  header4_bold,
  body1_regular,
  body1_semibold,
  body1_bold,
  body2_regular,
  body2_semibold,
  body2_bold,
  caption1_regular,
  caption1_semibold,
  caption1_bold,
  caption2_regular,
  caption2_semibold,
  caption2_bold,
  label1_semibold,
} = typography;

/**
 * Theme applied to all UI elements
 */
const theme = responsiveFontSizes(
  createTheme({
    // ========================
    // The color of the components
    // ========================
    palette: {
      mode: "light",
      primary: {
        main: color.PRIMARY_BRAND, //#0077C8
        light: color.PRIMARY_MAIN, //#00A9E0
        dark: color.PRIMARY_DARK, // #002F6C
        contrastText: color.WHITE, //#FFFFFF
      },
      secondary: {
        main: color.SECONDARY_MAIN, //#F6F6F6
        light: color.SECONDARY_LIGHT, //#E8E8E8
        lighter: color.SECONDARY_LIGHTER, //#F5F7FB
      },
      error: {
        main: color.ERROR_MAIN, //#E4002B
        light: color.ERROR_LIGHT, //#F44336
        dark: color.ERROR_DARK, //##FF84A6
      },
      success: {
        main: color.SUCCESS_MAIN, //#00B071
      },
      warning: {
        main: color.WARNING_MAIN, //#E57200
        light: color.WARNING_LIGHT, //#EBAA4D
        dark: color.WARNING_DARK, //#EF5713
      },
      info: {
        main: color.INFO_ALERTBOX_BLUE, //#F2F8FC
        light: color.INFO_ALERTBOX_RED, //#FFF3F6
        dark: color.INFO_ORANGE, //#F5C799
      },
      grey: {
        100: color.GREY100, //#CCCCCC
        400: color.GREY400, //#404040
        600: color.GREY600, //#666666
      },
      text: {
        primary: color.TEXT_PRIMARY, //#002F6C
        secondary: color.TEXT_SECONDARY, //#808080
      },
      common: {
        white: color.WHITE, //#FFFFFF
        black: color.BLACK, //#000000
      },
      divider: color.SECONDARY_LIGHT, //#E8E8E8
    },
    // ========================
    // The type sizes with the layout grid
    // ========================
    typography: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeightLight: fontWeights.fontWeightLight, //w2_light
      fontWeightRegular: fontWeights.fontWeightRegular, //w3_normal
      fontWeightMedium: fontWeights.fontWeightMedium, //w4_semiBold
      fontWeightBold: fontWeights.fontWeightBold, //w5_bold
      title_bold,
      header1_bold,
      header1_semibold,
      header1_regular,
      header2_bold,
      header2_semibold,
      header2_regular,
      header3_bold,
      header3_semibold,
      header3_regular,
      header4_bold,
      header4_semibold,
      header4_regular,
      body1_bold,
      body1_semibold,
      body1_regular,
      body2_bold,
      body2_semibold,
      body2_regular,
      caption1_bold,
      caption1_semibold,
      caption1_regular,
      caption2_bold,
      caption2_semibold,
      caption2_regular,
      label1_semibold,
    },
    // ========================
    // The spacing between the elements of UI
    // ========================
    spacing: size.SPACING as number,
    components: {
      // Refer to figma Typography: https://www.figma.com/file/E2wu7jK9MQueNaEa1m0vYd/NUHS-UI-Library?type=design&node-id=2234-912&mode=design&t=JWpIdKiAkpE6rILP-0
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            header1_bold: "h1",
            header1_semibold: "h1",
            header1_regular: "h1",
            header2_bold: "h2",
            header2_semibold: "h2",
            header2_regular: "h2",
            header3_bold: "h3",
            header3_semibold: "h3",
            header3_regular: "h3",
            header4_bold: "h4",
            header4_semibold: "h4",
            header4_regular: "h4",
            body1_bold: "p",
            body1_semibold: "p",
            body1_regular: "p",
            body2_bold: "p",
            body2_semibold: "p",
            body2_regular: "p",
            caption1_bold: "p",
            caption1_semibold: "p",
            caption1_regular: "p",
            caption2_bold: "p",
            caption2_semibold: "p",
            caption2_regular: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...{
              fontFamily:
                '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
              letterSpacing: "unset",
            },
            ...(ownerState.component === "span" && {
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "inherit",
            }),
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
        },
        styleOverrides: {
          root: {
            fontFamily:
              '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
            boxShadow: "none",
            textTransform: "none",
            ...body1_semibold,
            borderRadius: size.BORDER_RADIUS,
            height: size.BUTTONHEIGHT,
            padding: "8px 13px 8px 13px",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "unset",
            },
          },
          // ========================
          // MUI Text Button
          // ========================
          text: {
            color: color.PRIMARY_BRAND,
            ...body2_semibold,
            "&.Mui-disabled": {
              color: color.GREY100,
            },
          },

          // ====================
          // MUI Button Contained
          // ====================
          contained: {
            backgroundColor: color.PRIMARY_BRAND,
            color: color.WHITE,
            "&:hover": {
              backgroundColor: color.PRIMARY_BRAND,
              boxShadow: "none",
            },
            "&.Mui-disabled": {
              backgroundColor: color.GREY100,
              color: color.WHITE,
              "&:hover": {
                backgroundColor: color.GREY100,
                boxShadow: "none",
              },
            },
          },
          // ========================
          // MUI Outlined Buttons base
          // ========================
          outlined: {
            color: color.PRIMARY_BRAND,
            border: `1px solid ${color.PRIMARY_BRAND}`,
            backgroundColor: color.WHITE,
            "&.Mui-disabled": {
              backgroundColor: color.GREY100,
              color: color.WHITE,
              border: "none",
            },
          },

          // ========================
          // MUI Icon Buttons Primary
          // ========================
          startIcon: {
            fontSize: size.FONTSIZE_24,
            marginRight: "4px",
          },

          endIcon: {
            marginLeft: "4px",
            "& .MuiSvgIcon-root": {
              fontSize: size.FONTSIZE_24,
            },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily:
              '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
            color: color.GREY100,

            "&.Mui-selected": {
              color: color.TEXT_PRIMARY,
            },
          },
        },
      },

      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: color.TEXT_PRIMARY,
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: color.GREY100,
          },
          root: {
            width: "32px",
            height: "32px",
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            ...body2_regular,
            color: color.GREY400,
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            marginRight: size.SPACING,
          },
          switchBase: {
            color: color.SECONDARY_MAIN,
          },
          track: { backgroundColor: color.GREY600 },
          colorPrimary: {
            "&.Mui-checked": {
              opacity: "1",
            },
          },
          colorSecondary: {
            "&.Mui-checked": {
              color: color.WARNING_MAIN,
            },
            "&.Mui-checked + .MuiSwitch-track": {
              backgroundColor: color.INFO_ORANGE,
            },
          },
        },
      },

      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            padding: 0,
            backgroundColor: color.BLACK,
            borderRadius: "0.5rem",
            justifyContent: "center",
          },
        },
      },
      MuiCircularProgress: {
        defaultProps: {
          "aria-label": "MuiCircularProgress", //used for RTL getByLabelText
        },
      },

      MuiSvgIcon: {
        styleOverrides: {
          root: { fontSize: "2.142857142857143rem" },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            height: "48px",

            "& .MuiInputBase-root": {
              height: "48px",
              borderRadius: size.INPUT_BORDER_RADIUS,
              ...body1_regular,
              color: color.GREY400,
            },
            "& .MuiFormLabel-root": {
              top: "-4px",
              "&.MuiInputLabel-shrink": {
                top: "0px",
                transform: "translate(14px, -9px) scale(0.75)",
                backgroundColor: color.WHITE,
                paddingLeft: "2px",
                paddingRight: "2px",
                "&.Mui-disabled": {
                  backgroundColor: color.WHITE,
                  marginRight: "-5px",
                },
              },
            },
          },
        },
      },

      MuiSkeleton: {
        defaultProps: {
          "aria-label": "MuiSkeleton", //used for RTL getByLabelText
        },
      },

      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            fontWeight: 600,
            color: color.PRIMARY_BRAND,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "6px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "48px",
            borderRadius: size.BORDER_RADIUS,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },

    //change this disabled button style accordingly when updating button styling
    IHADisabled: {
      disabled: {
        backgroundColor: color.GREY100,
        color: color.WHITE,
        "&:hover": {
          backgroundColor: color.GREY100,
          color: color.WHITE,
        },
      },
    },
  }),
);

// !exp: this will add theme to window object for easier dev reference from console
// !exp: in console type window.theme.palette to see current palette settings
declare global {
  interface Window {
    theme: typeof theme;
  }
}

window.theme = theme;
const env = getEnvValue(REACT_APP_ENV);
// consoleSplash will not be invoked during jest run
if (!process.env.JEST_WORKER_ID && env !== "prd") consoleSplash();

export default theme;
