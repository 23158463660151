import { useState, useEffect } from "react";
import { sxStyles } from "./SelectRequest.styles";
import { prescribedMedicationConsent } from "lib/util/StandardPageUtil/prescribedMedication";
import FooterIcons from "./FooterIcons/FooterIcons";
import {
  Box,
  Typography,
  CardHeader,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import Page from "lib/components/layout/Page/Page";
import { shouldDisplayMedicationRecord } from "lib/util/MyChart/medicationRecord";
import { prescribedMedicationUrl } from "lib/configs";
import { navigateToExternalInMobileBrowserForStandardPage } from "lib/routing/navigate/navigate";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setConsentFlag } from "lib/redux/user";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";
import { selectUser } from "lib/redux/user/selectors";
import { selectMedRefill } from "ui/medrefill/ducks/selectors";
import { setSystemSettingsHasErrored } from "ui/medrefill/ducks";
import { PRESCRIBED_MEDICATION_CONSENT_CODE } from "api/shared/ValidateConsent/validateConsent.code";
import { fetchUserConsent } from "lib/redux/user/thunks";
import { fetchMyChartSystemSettings } from "ui/medrefill/ducks/thunks";

const SelectRequest = () => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // Redux states
  const {
    patientName: username,
    isLoading: isLoadingUsername,
    relationType,
  } = useAppSelector(selectUser);

  const {
    isLoading: isLoadingPrescribedMedicationConsent,
    consentFlag: prescribedMedicationConsentFlag,
  } = useAppSelector(selectUser).consent;

  const {
    isLoading: isLoadingSystemSettings,
    hasErrored: hasErroredSystemSettings,
    errorMessage: errorMessageSystemSettings,
  } = useAppSelector(selectMedRefill).systemSettings;

  const showMyChartMedication =
    useAppSelector(selectMedRefill).showMyChartMedication;

  // Local states
  const [pageFlag, setPageFlag] = useState(false);

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  useEffect(() => {
    // clear past detail and images cache only when loading
    dispatch(setSystemSettingsHasErrored(null));

    // fetch user consent against prescribed medication standard page if it is family and friend flow (relationType === 'F')
    // only when it is family and friend flow, we need to fetch user consent to determine if prescribed medication should be display
    // for logged in user flow, no need to fetch consent as consent is implicitly given
    // for relationType 'C', prescribed medication consent is always given, thus true
    if (relationType === "F") {
      dispatch(fetchUserConsent(PRESCRIBED_MEDICATION_CONSENT_CODE));
    }

    if (relationType === "C") {
      dispatch(setConsentFlag(true));
    }

    // !exp fetch flag to display My Chart medication
    dispatch(fetchMyChartSystemSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageActionObj?.action === "refreshLanding") {
      consumeMessageActionObj();
      setPageFlag(!pageFlag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumeMessageActionObj, messageActionObj?.action]);

  return (
    <Page>
      {isLoadingSystemSettings ? (
        // clicked re-order button, i.e. leaving the landing page, show circular progress instead of skeleton
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredSystemSettings ? (
        <ErrorDisplay
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() => {
            dispatch(fetchMyChartSystemSettings());
          }}
        />
      ) : (
        <Box sx={classes.mainContainer}>
          <Box>
            <Box sx={classes.profileHeader}>
              {isLoadingUsername ? (
                <CardHeader
                  title={
                    <Skeleton variant="text" animation="wave" width="60%" />
                  }
                  sx={classes.root}
                />
              ) : username ? (
                <Typography sx={classes.profileName}>
                  {username && username + "'s medication"}
                </Typography>
              ) : null}
            </Box>
          </Box>

          {/* Medication Footer Container*/}
          {/* not showing this section before user consent against prescribed medication is determined */}
          {!isLoadingPrescribedMedicationConsent && (
            <Box>
              {/* Icons Container */}
              <FooterIcons
                shouldDisplayMedicationRecord={shouldDisplayMedicationRecord(
                  relationType,
                  showMyChartMedication,
                )}
                prescribedMedicationConsentFlag={prescribedMedicationConsent(
                  relationType,
                  prescribedMedicationConsentFlag,
                )}
                prescriptionRedirect={() => {
                  const url = prescribedMedicationUrl();
                  navigateToExternalInMobileBrowserForStandardPage(url, "hh");
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Page>
  );
};

export default SelectRequest;
