import { Box, Button, CircularProgress, Typography } from "@mui/material";
import IMAGES from "lib/assets/images";
import CompactCard from "lib/components/cards/CompactCard/CompactCard";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { ACTIONS } from "lib/routing";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowserForStandardPage,
} from "lib/routing/navigate/navigate";
import { screenForLifeBackUrl } from "lib/configs/configs/config";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useEffect } from "react";
import { NUHS_POSTALCODE } from "./Constants";
import { sxStyles } from "./Dashboard.styles";
import { fetchCategoryList, fetchSystemSettings } from "ui/mhm/ducks";
import { fetchMyChartSystemSettings } from "ui/medrefill/ducks/thunks";
import {
  selectMhm,
  selectMhmCategoryList,
  selectMhmSystemSettings,
} from "ui/mhm/ducks/selectors";
import CategoryCard from "./CategoryCard/CategoryCard";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { MessageActions } from "lib/routing/messageChannel/messageActions";

const WELCOME_TITLE = "Welcome to My Health Map";
const SFL_TITLE = "Check your eligibility for Screen for Life";
const FOOTER_TITLE = "Useful Resources";
const DOS_CLINIC_TITLE = "Find a screening clinic near you";
const DOS_DINING_TITLE = "Find healthier eating options near you";

const PREVENTIVE_CARE_BUTTON_LABEL = "View recommendations";
const PREVENTIVE_CARE_DISCLAIMER =
  "*Only data from NUHS, NHG or Screen for Life (SFL) will be seen here.";
const CARDS_INTRO =
  "For more information about screening and vaccinations, tap on the cards below:";
const RETURNED_USER_DISCLAIMER_CODE = "CPDBRETUSRCONT";
const CLINIC_CATEGORY_CODE = "PR_CDM";

const Dashboard = () => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  // Redux states
  const {
    isLoading: isLoadingSystemSettings,
    hasErrored: hasErroredSystemSettings,
    errorMessage: errorMessageSystemSettings,
  } = useAppSelector(selectMhmSystemSettings);
  const returnedUserDisclaimerHtmlString = useAppSelector(
    selectMhmSystemSettings,
  ).systemSettingMap[RETURNED_USER_DISCLAIMER_CODE]?.DisplayValue;
  const categoryListValue = useAppSelector(selectMhmCategoryList).value;
  const {
    isLoading: categoryListIsLoading,
    hasErrored: categoryListHasErrored,
    errorMessage: categoryListErrorMessage,
  } = useAppSelector(selectMhmCategoryList);

  const showPreventiveCare =
    useAppSelector(selectMhm).showMyChartPreventiveCare;

  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_MHM_DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fetching system settings once only
    if (hasErroredSystemSettings === null) {
      dispatch(fetchSystemSettings());
      dispatch(fetchMyChartSystemSettings());
      dispatch(fetchCategoryList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErroredSystemSettings]);

  const showScreenForLifeLink =
    categoryListValue?.categories.find((x) => x.isSFLApplicable === true) !==
    undefined;

  const handleSFLOnClick = () => {
    navigateToExternalInMobileBrowserForStandardPage(
      screenForLifeBackUrl(),
      "hh",
    );
  };

  const handleDOSClicnicOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_SCREENING_CLINIC_NEAR_YOU);

    dispatch(
      setMessageToSend(
        MessageActions.dosClinic({
          postalCode: NUHS_POSTALCODE,
          serviceTypes: [CLINIC_CATEGORY_CODE],
        }),
      ),
    );
  };

  const handleDOSDiningPlaceOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_HEALTHIER_DINING_OPTIONS);

    dispatch(
      setMessageToSend(
        MessageActions.dosDining({
          postalCode: NUHS_POSTALCODE,
        }),
      ),
    );
  };

  const handleViewRecommendationsOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_MHM_PREVENTIVE_CARE);
    mobileNavigate(ACTIONS.MY_CHART.PREVENTIVE_CARE);
  };

  return (
    <>
      {isLoadingSystemSettings || categoryListIsLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={4}>
          <CircularProgress />
        </Box>
      ) : hasErroredSystemSettings ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageSystemSettings}
          onTryAgain={() => {
            dispatch(fetchSystemSettings());
            dispatch(fetchMyChartSystemSettings());
          }}
        />
      ) : categoryListHasErrored ? (
        <ErrorDisplayContainer
          errorMessage={categoryListErrorMessage}
          onTryAgain={() => {
            dispatch(fetchCategoryList());
          }}
        />
      ) : (
        <Box sx={classes.mainContainer}>
          <Box sx={classes.introContainer}>
            <Typography sx={classes.introTitle}>{WELCOME_TITLE}</Typography>
            <Typography sx={classes.introBody}>
              {MapRawStringToReactElement(
                returnedUserDisclaimerHtmlString ?? "",
              )}
            </Typography>
          </Box>

          {showPreventiveCare && (
            <Box sx={classes.preventiveCareContainer}>
              <Box sx={classes.preventativeCareButtonContainer}>
                <Button onClick={handleViewRecommendationsOnClick} fullWidth>
                  {PREVENTIVE_CARE_BUTTON_LABEL}
                </Button>
              </Box>
              <Typography sx={classes.preventiveCareDisclaimer}>
                {PREVENTIVE_CARE_DISCLAIMER}
              </Typography>
            </Box>
          )}

          <Typography sx={classes.cardsIntro}>{CARDS_INTRO}</Typography>

          <Box sx={classes.cardWrapper}>
            {categoryListValue?.categories.map((item) => {
              return <CategoryCard key={item.code} name={item} />;
            })}
          </Box>

          <Box sx={classes.footerContainer}>
            <Typography sx={classes.footerTitle}>{FOOTER_TITLE}</Typography>
            <Box>
              {showScreenForLifeLink && (
                <CompactCard
                  title={SFL_TITLE}
                  titleIcon={IMAGES.healthTogetherMHM.SFL_Logo}
                  onClickCard={handleSFLOnClick}
                />
              )}

              <CompactCard
                title={DOS_CLINIC_TITLE}
                titleIcon={IMAGES.healthTogetherMHM.FindADoctorIcon}
                onClickCard={handleDOSClicnicOnClick}
              />

              <CompactCard
                title={DOS_DINING_TITLE}
                titleIcon={IMAGES.healthTogetherMHM.HDO_Logo}
                onClickCard={handleDOSDiningPlaceOnClick}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
