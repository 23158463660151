import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { Box, CircularProgress } from "@mui/material";
import Page from "lib/components/layout/Page/Page";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import UserProfileContainer from "ui/appointment/components/common/user/UserProfile/UserProfileContainer";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ConfirmationDetails from "../ConfirmationDetails/ConfirmationDetails";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentMeta,
  selectCreateAppointment,
} from "ui/appointment/ducks/selectors";
import { selectUser } from "lib/redux/user/selectors";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { handleErrorModal } from "lib/redux/notifications";
import { createLinkedAppointment } from "api/appointment/CreateLinkedAppointment/createLinkedAppointment";
import { createAppointment } from "api/appointment/CreateAppointment/createAppointment";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";

export const selectSlotDateTime = (
  selectedAvailableSlotDateTime: string | null,
): Date => {
  if (selectedAvailableSlotDateTime) {
    const slotDateTime = getDateFromISOString(selectedAvailableSlotDateTime);

    if (slotDateTime) {
      return slotDateTime;
    }
  }
  return new Date();
};

const ConfirmationPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux States
  const { memberIdentifier } = useAppSelector(selectUser);
  const {
    selectedSpecialty,
    selectedAvailableSlotId: slotId,
    selectedInstitution,
    selectedService,
    selectedAvailableSlotDateTime,
  } = useAppSelector(selectCreateAppointment);

  const {
    institutionCode,
    location,
    visitTypeId,
    appointmentType,
    calendarTitle,
    calendarLocation,
    zoneInfo,
    isHsgAppt,
  } = useAppSelector(selectAppointmentMeta);

  const institution = selectedInstitution || "";
  const service = selectedService || "";
  const slotDateTime = selectSlotDateTime(selectedAvailableSlotDateTime);

  // Local states
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Dialog open & close handlers
  const [afterCareMessage, setAfterCareMessage] = useState<string | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Request Handler
  const sendCreateAppointmentRequest = useCallback(async () => {
    try {
      // make the booking
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_CONFIRMATION);
      setIsLoading(true);
      let response = null;
      if (selectedSpecialty?.isFirstVisit) {
        response = await createLinkedAppointment(
          memberIdentifier,
          institutionCode,
          [slotId],
        );
      } else {
        response = await createAppointment({
          visitTypeId,
          memberIdentifier,
          start: formatDateToISO(slotDateTime),
          slotId,
          institutionCode,
          isHsgAppt,
        });
      }

      setAfterCareMessage(response.PatientInstruction);
      handleOpen();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          handleErrorModal({
            open: true,
            message: error.response?.data.Message,
            buttonText: error.response?.data.IsLiveChatEnabled
              ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
              : undefined,
          }),
        );
      } else {
        dispatch(
          handleErrorModal({
            open: true,
            message: null,
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    dispatch,
    visitTypeId,
    memberIdentifier,
    slotDateTime,
    slotId,
    institutionCode,
    isHsgAppt,
    selectedSpecialty,
  ]);

  return (
    <Page>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Confirmation Page & Details */}
          <VerticalSpreadLayout>
            <Box>
              <UserProfileContainer showMakeAppointmentButton={false} />
              <ConfirmationDetails
                institution={institution}
                location={location}
                zoneInfo={zoneInfo}
                service={
                  service
                    ? service
                    : selectedSpecialty
                      ? `Private ${selectedSpecialty?.description} - First Visit`
                      : ""
                }
                slotDateTime={slotDateTime}
                appointmentType={appointmentType}
              />
            </Box>
            <Box sx={{ m: 2 }}>
              <ButtonsFooter
                nextButtonText="Book this appointment"
                cancelButtonText="Back"
                onClickNext={sendCreateAppointmentRequest}
                onClickCancel={() => navigate(-1)}
              />
            </Box>
          </VerticalSpreadLayout>

          {/* Save to Calendar Modal */}
          <ConfirmationModal
            open={open}
            title="Appointment Booked"
            body={
              afterCareMessage !== null
                ? MapRawStringToReactElement(afterCareMessage)
                : null
            }
            hideNextButton={!calendarLocation && !location}
            nextButtonText="Add to calendar"
            cancelButtonText="Go to Appointment"
            onClickNext={() => {
              mobileNavigate(
                formatAddToCalendarURI(
                  "0",
                  slotDateTime,
                  calendarLocation ? calendarLocation : location,
                  institution || "",
                  calendarTitle,
                ),
              );
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClose={handleClose}
          />
        </>
      )}
    </Page>
  );
};

export default ConfirmationPage;
