import { CreateAppointmentReferredByFormPayload } from "./createAppointmentRequestFormData.types";

/**
 * Mock data for appointment forms
 */

export const Specialty: string[] =
  "Anaesthesiology|Cardiology|Cardiothoracic Surgery|Colorectal Surgery|Dentistry|Dermatology|Diagnostic Radiology|Emergency Medicine|Endocrinology|Family Medicine|Gastroenterology|General Surgery|Geriatric Medicine|Haematology|Hand Surgery|Infectious Diseases|Internal Medicine|Medical Oncology|Neonatology|Neurology|Neurosurgery|Nuclear Medicine|Obstetrics & Gynaecology|Occupational Health & Epidemiology|Ophthalmology|Oral & Maxillofacial Surgery|Orthopaedic Surgery|Otolaryngology (Ear, Nose & Throat or ENT)|Paediatric Medicine|Palliative Medicine|Pathology|Plastic Surgery & Burns|Psychiatric Medicine|Radiation Oncology|Rehabilitation Medicine|Renal Medicine|Respiratory Medicine|Rheumatology & Immunology|Sports Medicine|Surgical Oncology|Urology".split(
    "|",
  );

export const REFERRED_BY: CreateAppointmentReferredByFormPayload[] = [
  {
    id: "1",
    value: "Myself",
    label: "Myself<br/>No referral from Polyclinic / CHAS GP",
  },
  {
    id: "2",
    value: "Doctor",
    label: "GP / Family Doctor",
  },
  {
    id: "3",
    value: "Specialist",
    label: "Specialist",
  },
];
