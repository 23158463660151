import { parseResponse } from "api/resources/handlers/parseResponse/parseResponse";
import { GetPractitionerList } from "./GetPractitionerList.types";
import axios from "axios";
import { GET_PRACTITIONER_LIST } from "api/resources/configs/URL";
import { getRequestOptions } from "api/resources/configs/requests";
import { formatGetPractitionerListErrorMessage } from "api/resources/messages/ErrorMessage";

const getPractitionerList = async ({
  specialtyCode,
  institutionCode,
  memberIdentifier,
}: {
  specialtyCode: string;
  institutionCode: string;
  memberIdentifier: string;
}): Promise<GetPractitionerList> => {
  try {
    const response = await axios.post<GetPractitionerList>(
      GET_PRACTITIONER_LIST(),
      createRequestPayload({
        specialtyCode,
        institutionCode,
        memberIdentifier,
      }),
      getRequestOptions(),
    );
    return parseResponse(response, GET_PRACTITIONER_LIST());
  } catch (error) {
    const msg = formatGetPractitionerListErrorMessage(GET_PRACTITIONER_LIST());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  specialtyCode,
  institutionCode,
  memberIdentifier,
}: {
  specialtyCode: string;
  institutionCode: string;
  memberIdentifier: string;
}) => {
  return {
    SpecialtyCode: specialtyCode,
    InstitutionCode: institutionCode,
    MemberIdentifier: memberIdentifier,
  };
};

export { getPractitionerList };
