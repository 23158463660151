import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { ACTIONS, PATHS } from "lib/routing";
import Page from "lib/components/layout/Page/Page";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import UserProfileContainer from "ui/appointment/components/common/user/UserProfile/UserProfileContainer";
import ConfirmationDetails from "../ConfirmationDetails/ConfirmationDetails";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { rescheduleLinkedAppointment } from "api/appointment/RescheduleLinkedAppointment/rescheduleLinkedAppointment";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import { AxiosError } from "axios";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { selectUser } from "lib/redux/user/selectors";
import {
  selectAppointmentMeta,
  selectAppointments,
} from "ui/appointment/ducks/selectors";
import { parseISO } from "date-fns";
import { rescheduleAppointment } from "api/appointment/RescheduleAppointment/rescheduleAppointment";
import { createAppointment } from "api/appointment/CreateAppointment/createAppointment";
import { handleErrorModal } from "lib/redux/notifications";

/**
 * The appearance of a reschedule confirmation page
 * Used in reschedule appointment flow and rebooking missed appointments flow
 * Contains ConfirmationDetails
 */
const parseDateTime = (serializedDate: string | null) => {
  if (serializedDate) {
    // assume the date can be de-serialized
    return parseISO(serializedDate);
  } else {
    return null;
  }
};

const ConfirmationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Redux
  const memberIdentifier = useAppSelector(selectUser).memberIdentifier;
  const {
    selectedInstitution: institution,
    selectedService: service,
    selectedAvailableSlotExtensionData: extensionData,
    selectedAvailableSlotId: rescheduleSlotId,
  } = useAppSelector(selectAppointments).reschedule;
  const slotDateTime = parseDateTime(
    useAppSelector(selectAppointments).reschedule.selectedDateTime,
  );
  const rescheduledSlotDateTime = parseDateTime(
    useAppSelector(selectAppointments).reschedule.selectedAvailableSlotDateTime,
  );
  const slotId =
    useAppSelector(selectAppointments).create?.selectedAvailableSlotId;

  const {
    appointmentId: existingAppointmentId,
    targetSystem,
    institutionCode,
    appointmentType,
    visitTypeId,
    calendarTitle,
    calendarLocation,
    departmentName,
  } = useAppSelector(selectAppointmentMeta);
  const isHsgAppt = useAppSelector(selectAppointmentMeta).isHsgAppt || false;
  const appointment =
    useAppSelector(selectAppointments).init.lookup[existingAppointmentId || 0];
  const reminderMsg = appointment?.reminder ? appointment.reminder : "";

  // Dialog open & close handlers
  const [afterCareMessage, setAfterCareMessage] = useState<string | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isLinkedAppt: boolean =
    JSON.parse(extensionData ?? "{}")
      .IsLinkedAppt?.trim()
      .toLowerCase() === "true"
      ? true
      : false;

  // Request handler
  const sendRescheduleOrCreateRequest = useCallback(async () => {
    // !exp Flow used for Single Appointments
    if (!isLinkedAppt) {
      try {
        if (rescheduledSlotDateTime) {
          logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_CONFIRMATION);
          // Make the request
          setIsLoading(true);
          // upcoming appointment call reschedule appointment API
          // missed appointment call create appointment API
          const response =
            appointmentType === "missed"
              ? await createAppointment({
                  visitTypeId,
                  memberIdentifier,
                  start: formatDateToISO(slotDateTime),
                  slotId,
                  institutionCode,
                  isHsgAppt,
                })
              : await rescheduleAppointment({
                  memberIdentifier,
                  existingAppointmentId,
                  rescheduledSlotDateTime: formatDateToISO(
                    rescheduledSlotDateTime,
                  ),
                  institutionCode,
                  rescheduleSlotId,
                  visitTypeId,
                  isHsgAppt,
                });

          if (appointmentType !== "missed") {
            dispatch(setMessageToSend(MessageActions.rating()));
          }
          setAfterCareMessage(response.PatientInstruction);
          handleOpen();
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          dispatch(
            handleErrorModal({
              open: true,
              message: error.response?.data.Message,
              buttonText: error.response?.data.IsLiveChatEnabled
                ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
                : undefined,
            }),
          );
        } else {
          dispatch(handleErrorModal({ open: true, message: null }));
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      // !exp Flow used for Single Linked Appointment
      // !exp Used when there is only 1 linked appointment left, so that the correct API is being called.
      try {
        logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_CONFIRMATION);
        setIsLoading(true);

        await rescheduleLinkedAppointment(memberIdentifier, institutionCode, [
          slotId,
        ]);
        dispatch(setMessageToSend(MessageActions.rating()));

        setAfterCareMessage(reminderMsg);
        handleOpen();
      } catch (error) {
        if (error instanceof AxiosError) {
          dispatch(
            handleErrorModal({
              open: true,
              message: error.response?.data.Message,
              buttonText: error.response?.data.IsLiveChatEnabled
                ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
                : undefined,
            }),
          );
        } else {
          dispatch(handleErrorModal({ open: true, message: null }));
        }
      } finally {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLinkedAppt,
    rescheduledSlotDateTime,
    appointmentType,
    memberIdentifier,
    slotId,
    extensionData,
    targetSystem,
    institutionCode,
    existingAppointmentId,
    departmentName,
    dispatch,
  ]);

  return (
    <Page>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Confirmation Page & Details */}
          <VerticalSpreadLayout>
            <Box>
              <UserProfileContainer showMakeAppointmentButton={false} />
              <ConfirmationDetails
                location={institution}
                service={service}
                slotDateTime={slotDateTime}
                rescheduledSlotDateTime={rescheduledSlotDateTime}
              />
            </Box>
            <Box sx={{ m: 2 }}>
              <ButtonsFooter
                nextButtonText="Back"
                cancelButtonText="Proceed to reschedule"
                onClickNext={() => navigate(-1)}
                onClickCancel={sendRescheduleOrCreateRequest}
              />
            </Box>
          </VerticalSpreadLayout>

          {/* Save to Calendar Modal */}
          <ConfirmationModal
            open={open}
            title="Appointment Changed"
            body={MapRawStringToReactElement(afterCareMessage || "")}
            nextButtonText="Refill medication"
            cancelButtonText="Go to Appointment"
            hideAdditionalButton={false}
            additionalButtonText="Add to calendar"
            onClickNext={() => {
              mobileNavigate(ACTIONS.MOBILE_MEDICATION_LANDING);
              setOpen(true);
            }}
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClickAdditional={() => {
              mobileNavigate(
                formatAddToCalendarURI(
                  existingAppointmentId || "",
                  rescheduledSlotDateTime || new Date(),
                  calendarLocation || "",
                  institution || "",
                  calendarTitle,
                ),
              );

              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClose={handleClose}
          />
        </>
      )}
    </Page>
  );
};

export default ConfirmationPage;
