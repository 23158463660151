import { configureStore } from "@reduxjs/toolkit";
import { RootState, RootStore } from "./redux.types";
import rootReducer from "./rootReducer";
import { persistStore } from "redux-persist";

/* Creating a store with the rootReducer and the middleware. */
export const store: RootStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export type AppStore = ReturnType<typeof setupStore>;

export const persistor = persistStore(store);
